var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from "react";
import { changeThemeColor } from "@app/util/func";
import { connect, GLUtil } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
var PageContainer = /** @class */ (function (_super) {
    __extends(PageContainer, _super);
    function PageContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PageContainer.prototype.componentDidMount = function () {
        var unit = GLUtil.pathParse(PathConfig.Unit).unit;
        changeThemeColor(unit);
    };
    PageContainer.prototype.render = function () {
        return React.createElement("div", { className: "page-container " + (this.props.isClosed ? "" : "has-retirement-banner") }, this.props.children);
    };
    PageContainer = __decorate([
        connect(function (_a) {
            var isClosed = _a.retirement.isClosed;
            return ({
                isClosed: isClosed
            });
        })
    ], PageContainer);
    return PageContainer;
}(Component));
export default PageContainer;
