var TYPES = {
    IAccessUnitService: Symbol("IAccessUnitService"),
    ISettingService: Symbol("ISettingService"),
    IResourceService: Symbol("IResourceService"),
    ITSIService: Symbol("ITSIService"),
    IHeartBeatService: Symbol("IHeartBeatService"),
    ITSILogService: Symbol("ITSILogService"),
    IStudentAppService: Symbol("IStudentAppService"),
};
export { TYPES };
