var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React, { Component } from "react";
import MediaPlayer, { MediaType, MediaExtType, random, MediaStreamType, PlayAction } from "./mediaplayer";
import ResourcesCache from "@app/util/resources-cache";
import { lazyInject, TYPES, ContentGroupEnum as ContentGroup, isMobileOrTablet } from "@app/util";
import { isSignatureChanged } from "@app/states/signature";
import { connect } from "gl-commonui";
var PRELOADTHRESHOLD = 5;
var ContentPlayer = /** @class */ (function (_super) {
    __extends(ContentPlayer, _super);
    function ContentPlayer(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            resources: { contents: [] },
            remainingCount: 0,
            total: 0,
            isPaused: true,
            loaded: false,
            content: {},
            times: { time: 0, duration: 0, last: 0, lastTime: '' }
        };
        return _this;
    }
    ContentPlayer.prototype.componentDidMount = function () {
        this.resourcesCache = new ResourcesCache();
        var resources = this.props.resources;
        if (resources
            && resources.playlists
            && resources.playlists.length > 0
            && resources.contents
            && resources.contents.length > 0) {
            this.formatResources(this.props);
        }
    };
    ContentPlayer.prototype.componentWillReceiveProps = function (_a) {
        var signature = _a.signature, resources = _a.resources, playlistId = _a.playlistId, onLoaded = _a.onLoaded, stream = _a.stream, streamPreload = _a.streamPreload, playAction = _a.playAction;
        if (this.props.resources !== resources) {
            this.formatResources({ signature: signature, resources: resources, playlistId: playlistId, onLoaded: onLoaded, stream: stream, streamPreload: streamPreload });
        }
        if (isSignatureChanged(this.props.signature, signature)) {
            this.updateSignature(resources, stream, signature);
        }
        if (this.props.playAction !== playAction) {
            switch (playAction) {
                case PlayAction.play:
                    this.controller && this.controller.play();
                    break;
                case PlayAction.pause:
                    this.controller && this.controller.pause();
                    break;
                default:
                    this.controller && this.controller.stop && this.controller.stop();
                    break;
            }
        }
    };
    ContentPlayer.prototype.updateSignature = function (resources, stream, signature) {
        var changeSignature = function (signature) { return function (m) { return (m.url = replaceSig(m.url, signature)); }; };
        resources.contents.forEach(function (content) {
            if (content.description && content.description.mediaType === MediaType.video) {
                content.medias.forEach(changeSignature(signature.videoContainer));
            }
            else if (stream) {
                content.pages.forEach(changeSignature(signature.pageContainer));
                content.medias.forEach(changeSignature(signature.audioContainer));
            }
        });
    };
    ContentPlayer.prototype.formatResources = function (_a) {
        var _this = this;
        var signature = _a.signature, resources = _a.resources, playlistId = _a.playlistId, onLoaded = _a.onLoaded, stream = _a.stream, streamPreload = _a.streamPreload;
        var playlist = playlistId ? resources.playlists.find(function (pl) { return pl.id === playlistId; }) : resources.playlists[0];
        resources.contents = playlist.contents.reduce(function (contents, contentId) {
            return contents.concat(resources.contents
                .filter(function (content) { return content.id === contentId.id; })
                .map(function (content) {
                content.medias = content.medias.filter(function (m) { return m.mediaType !== MediaExtType.PDF; });
                content.pages = content.pages.sort(function (pre, cur) { return pre.sequence - cur.sequence; });
                return content;
            }));
        }, []).map(function (content, index) { return (content.sequence = index, content); });
        this.setState({ total: resources.contents.length, loaded: false });
        var formatMedia = function (updateTime) { return function (signature) { return function (m) {
            var src = replaceSig(m.url, signature);
            m.url = src;
            m.src = src;
            m.updateTime = updateTime;
            return m;
        }; }; };
        var cacheResources = resources.contents.reduce(function (resources, content) {
            var replaceSignatureUrl = formatMedia(content.updateTime);
            switch (content.contentGroup) {
                case ContentGroup.VPC:
                case ContentGroup.Story:
                case ContentGroup.Song:
                    return resources
                        .concat(content.pages.map(replaceSignatureUrl(signature.pageContainer)))
                        .concat(content.medias.map(replaceSignatureUrl(signature.audioContainer)));
                case ContentGroup.Movie:
                    return resources.concat(content.medias.map(replaceSignatureUrl(signature.videoContainer)));
            }
        }, []);
        var resourcesLoaded = function (promises) { return Promise
            .all(promises)
            .then(function (_) {
            var setResource = function (audios) {
                audios.length > 0 && _this.setVPCBackgroundAudio(resources.contents, audios);
                _this.setState({ resources: resources, loaded: true });
                onLoaded && onLoaded(true);
            };
            if (_this.props.backgroundAudios) {
                var bkAudiosPromises = _this.props.backgroundAudios.map(function (audio) { return _this.resourcesCache.getUrl(audio); });
                Promise.all(bkAudiosPromises).then(function (audios) {
                    setResource(audios);
                }).catch(function (_) {
                    setResource(_this.props.backgroundAudios.map(function (audio) { return audio.src; }));
                });
            }
            else {
                setResource([]);
            }
        }); };
        if (!stream && !isMobileOrTablet() && this.resourcesCache.isSupport()) {
            cacheResources = cacheResources.filter(function (c) { return c.mediaType !== MediaExtType.MP4; });
            this.resourcesCache.init().then(function (_) {
                var replaceCacheUrl = function (m) { return _this.resourcesCache
                    .getCachedUrl(m)
                    .then(function (url) { return Promise.resolve(m.url = url); }); };
                var caches = function () {
                    var preCached = cacheResources.splice(0, PRELOADTHRESHOLD);
                    return _this.resourcesCache.caches(preCached)
                        .then(function () { return Promise.all(preCached.map(function (media) { return replaceCacheUrl(media); })); })
                        .then(function () { return _this.resourcesCache.restoreVersions(); });
                };
                var preLoad = function () {
                    if (cacheResources.length === 0)
                        return Promise.resolve(true);
                    return caches().then(preLoad);
                };
                var preCaches = caches();
                var promises = resources.contents.reduce(function (promises, content) {
                    return promises.concat(_this.formatContent(content, {
                        type: MediaStreamType.smooth,
                        setAudioUrl: function () { return preCaches; },
                        setVideoUrl: function () { return _this.getVideoPromise(content); }
                    }));
                }, []);
                resourcesLoaded(promises).then(preLoad);
            });
        }
        else {
            var preLoadImages_1 = streamPreload === false ? [] : this.streamPreloadImage(resources.contents);
            var promises = resources.contents.reduce(function (promises, content) {
                return promises.concat(_this.formatContent(content, {
                    type: MediaStreamType.smooth,
                    setAudioUrl: function () { return preLoadImages_1.splice(0, PRELOADTHRESHOLD).map(function (p) { return p(); }); },
                    setVideoUrl: function () { return _this.getVideoPromise(content); }
                }));
            }, []);
            var preLoad_1 = function () {
                if (preLoadImages_1.length === 0)
                    return;
                Promise
                    .all(preLoadImages_1
                    .splice(0, PRELOADTHRESHOLD)
                    .map(function (p) { return p(); }))
                    .then(preLoad_1);
            };
            resourcesLoaded(promises).then(preLoad_1);
        }
    };
    ContentPlayer.prototype.getVideoPromise = function (content) {
        if (content.medias.every(function (m) { return m.mediaStreamType === MediaStreamType.smooth; })) {
            return Promise.resolve();
        }
        else {
            return this.resourceService.getVideoStreaming({ contentId: content.id })
                .then(function (d) { return Promise.resolve(content.medias.forEach(function (m) { return m.url = d.mediaUrl; })); });
        }
    };
    ContentPlayer.prototype.streamPreloadImage = function (contents) {
        return contents.reduce(function (promises, content) {
            return promises.concat(content.pages.map(function (p) { return function () { return new Promise(function (resolve, reject) {
                var img = document.createElement("img");
                img.style.display = "none";
                img.className = "preload-img";
                img.src = p.url;
                document.body.appendChild(img);
                img.onload = function () { return resolve(true); };
                img.onerror = function () { return reject(); };
            }); }; }));
        }, []);
    };
    ContentPlayer.prototype.formatContent = function (content, _a) {
        var type = _a.type, setVideoUrl = _a.setVideoUrl, setAudioUrl = _a.setAudioUrl;
        var description = content.description = { mediaType: MediaType.video };
        var promise = [];
        setAudioUrl = setAudioUrl || (function () { return Promise.resolve(true); });
        setVideoUrl = setVideoUrl || (function () { return Promise.resolve(true); });
        switch (content.contentGroup) {
            case ContentGroup.VPC:
                description.mediaType = MediaType.audio;
                description.singleCycle = 2;
                description.interval = { pre: 1300, mid: 1300 };
                return promise.concat(setAudioUrl());
            case ContentGroup.Story:
            case ContentGroup.Song:
                description.mediaType = MediaType.audio;
                return promise.concat(setAudioUrl());
            case ContentGroup.Movie:
                description.type = type;
                return promise.concat(setVideoUrl());
        }
    };
    ContentPlayer.prototype.setVPCBackgroundAudio = function (contents, backgroundAudios) {
        backgroundAudios = (backgroundAudios || []).sort(random);
        var shiftLoop = function () {
            var audio = backgroundAudios.shift();
            backgroundAudios.push(audio);
            return audio;
        };
        contents.reduce(function (pre, cur) {
            if (!pre.description && cur.contentGroup === ContentGroup.VPC) {
                cur.description.interval.pre = 1500;
                cur.description.backgroundAudio = {
                    uri: shiftLoop(),
                    start: true,
                };
                return cur;
            }
            if (pre.contentGroup !== ContentGroup.VPC && cur.contentGroup === ContentGroup.VPC) {
                cur.description.interval.pre = 1500;
                cur.description.backgroundAudio = {
                    uri: shiftLoop(),
                    start: true,
                };
                return cur;
            }
            if (pre.contentGroup === ContentGroup.VPC && cur.contentGroup !== ContentGroup.VPC) {
                pre.description.interval.post = 1500;
                pre.description.backgroundAudio = {
                    end: true,
                };
                return cur;
            }
            if (cur.contentGroup === ContentGroup.VPC) {
                cur.description.backgroundAudio = {
                    middle: true,
                };
                return cur;
            }
            return cur;
        }, {});
    };
    ContentPlayer.prototype.getController = function (controller) {
        var _this = this;
        this.controller = controller;
        this.controller.onPlay = function () {
            _this.setState({ isPaused: false });
            var onPlay = _this.props.onPlay;
            onPlay && onPlay();
        };
        this.controller.onPause = function () {
            _this.setState({ isPaused: true });
            var onPause = _this.props.onPause;
            onPause && onPause();
        };
        this.controller.onEnded = function () {
            var onEnded = _this.props.onEnded;
            onEnded && onEnded(_this.state.times);
        };
        this.controller.onCurrentEnded = function () {
            var onCurrentEnded = _this.props.onCurrentEnded;
            onCurrentEnded && onCurrentEnded(_this.state.content, _this.state.times);
        };
    };
    ContentPlayer.prototype.render = function () {
        var _this = this;
        var _a = this.state, resources = _a.resources, loaded = _a.loaded, remainingCount = _a.remainingCount, total = _a.total, isPaused = _a.isPaused, content = _a.content, times = _a.times;
        var _b = this.props, onStop = _b.onStop, children = _b.children, visibleBar = _b.visibleBar, visibleMedia = _b.visibleMedia, onCurrentStarted = _b.onCurrentStarted, startIndex = _b.startIndex, playMode = _b.playMode, autoplay = _b.autoplay, sequenceMode = _b.sequenceMode, currentTime = _b.currentTime, onTimeUpdate = _b.onTimeUpdate, fullScreen = _b.fullScreen, rest = __rest(_b, ["onStop", "children", "visibleBar", "visibleMedia", "onCurrentStarted", "startIndex", "playMode", "autoplay", "sequenceMode", "currentTime", "onTimeUpdate", "fullScreen"]);
        var captionItem = null;
        var controllerItem = null;
        React.Children.forEach(this.props.children, function (child, index) {
            if (child && child.type && child.type.displayName == "Caption") {
                captionItem = child;
            }
            else {
                controllerItem = child;
            }
        });
        var controlBar = !controllerItem ? null : React.cloneElement(React.Children.only(controllerItem), __assign({}, rest, { times: times, isPaused: isPaused, remainingCount: remainingCount, total: total, content: content, startIndex: startIndex, playMode: playMode, play: function () { return _this.controller.play(); }, pause: function () { return _this.controller.pause(); }, paused: function () { return _this.controller.paused(); }, stop: function () {
                _this.controller.stop();
                onStop && onStop(_this.state.times);
            }, seek: function (seconds) { return _this.controller.seek(seconds); }, repeat: function (again) { return _this.controller.repeat(again); }, setPlayMode: function (mode) { return _this.controller.setPlayMode(mode); } }));
        var caption = !captionItem ? null : React.cloneElement(React.Children.only(captionItem), __assign({}, rest, { times: times, contentId: content ? content.id : undefined }));
        return React.createElement("div", { className: "contentplayer" },
            React.createElement(MediaPlayer, { autoplay: autoplay, resources: resources, getController: this.getController.bind(this), onCurrentStarted: function (remainingCount, content) {
                    _this.setState({ remainingCount: remainingCount, content: content });
                    onCurrentStarted && onCurrentStarted({ remainingCount: remainingCount, total: total, content: content });
                }, onTimeUpdate: function (times) {
                    _this.setState({ times: times });
                    onTimeUpdate && onTimeUpdate(times);
                }, visibleBar: visibleBar, visibleMedia: visibleMedia, startIndex: startIndex, playMode: playMode, sequenceMode: sequenceMode, playbackRate: this.props.playbackRate, currentTime: currentTime, fullScreen: fullScreen },
                loaded && caption,
                loaded && controlBar));
    };
    __decorate([
        lazyInject(TYPES.IResourceService)
    ], ContentPlayer.prototype, "resourceService", void 0);
    ContentPlayer = __decorate([
        connect(function (_a) {
            var signature = _a.signature.signature;
            return ({
                signature: signature,
            });
        }, null, function (_a) {
            var nextSignature = _a.state.signature, signature = _a.own.signature;
            return ({
                signature: Object.keys(nextSignature).length > 0 ? nextSignature : signature
            });
        })
    ], ContentPlayer);
    return ContentPlayer;
}(Component));
export default ContentPlayer;
function replaceSig(uri, sig) {
    if (uri.lastIndexOf('?') > -1) {
        return uri.replace(/\?.*/, sig);
    }
    else {
        return "" + uri + sig;
    }
}
