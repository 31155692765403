var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from "react";
import { connect } from "gl-commonui";
import { AccessStatus } from "@app/states/studentcodebase";
export default (function (ChildComponent) {
    var WrapperComponent = /** @class */ (function (_super) {
        __extends(WrapperComponent, _super);
        function WrapperComponent() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.state = {
                isLoading: _this.props.accessStatus === AccessStatus.DEFAULT ? true : false
            };
            return _this;
        }
        WrapperComponent.prototype.componentDidUpdate = function (prevProps) {
            if (prevProps.accessStatus !== this.props.accessStatus &&
                this.props.accessStatus === AccessStatus.UNLOCK) {
                this.setState({ isLoading: false });
            }
        };
        WrapperComponent.prototype.render = function () {
            var view = this.state.isLoading ? (React.createElement("div", null)) : (React.createElement(ChildComponent, __assign({}, this.props)));
            return view;
        };
        WrapperComponent = __decorate([
            connect(function (_a) {
                var accessStatus = _a.studentcodebase.accessStatus;
                return ({
                    accessStatus: accessStatus
                });
            })
        ], WrapperComponent);
        return WrapperComponent;
    }(React.Component));
    return WrapperComponent;
});
