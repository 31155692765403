var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { GLGlobal } from "gl-commonui";
import MobileDetect from "mobile-detect";
import enableInlineVideo from 'iphone-inline-video';
import * as bowser from 'bowser';
import canAutoPlay from 'can-autoplay';
export function couldAutoPlay() {
    return Promise.all([
        canAutoPlay.audio({ inline: true, timeout: 1000 }),
        canAutoPlay.video({ inline: true, timeout: 1000 })
    ]).then(function (_a) {
        var _b = __read(_a, 2), audio = _b[0], video = _b[1];
        if (audio.result && video.result) {
            return Promise.resolve([audio.result, video.result]);
        }
        return Promise.reject([audio.error, video.error]);
    });
}
export function addMediaAttributesForIOS(mediaId) {
    var media = document.getElementById(mediaId);
    if (media) {
        enableInlineVideo(media);
        enableInlineVideo(media, { iPad: true });
    }
}
export function guidEmpty() {
    return '00000000-0000-0000-0000-000000000000';
}
export function newGuid() {
    var u = (new Date()).getTime().toString(16) +
        Math.random().toString(16).substring(2) + "0".repeat(16);
    var guid = u.substr(0, 8) + '-' + u.substr(8, 4) + '-4000-8' +
        u.substr(12, 3) + '-' + u.substr(15, 12);
    return guid;
}
export function getCountryCode() {
    return localStorage.getItem('gl-common.countrycode') || '';
}
export function isInChina() {
    return getCountryCode().toUpperCase() === 'CN';
}
export function getMediaPrefix() {
    return getMediaStorage().mediaprefix;
}
function changeMediaPrefix(src) {
    return isInChina() ? "" + getMediaPrefix() + src.split('/').pop() : src;
}
export function getMediaStorage() {
    return process.env.mediaStorage;
}
export function getTeachingResource() {
    return getMediaStorage().teachingResource;
}
export function getVpcBackgroundUrls() {
    return getMediaStorage().vpcbackground.uris.map(function (uri) { return changeMediaPrefix(uri); });
}
export function getVpcBackgrounds() {
    return getVpcBackgroundUrls().map(function (src) { return ({ src: src, id: src, updateTime: '2019-05-10 00:00:00' }); });
}
export function getSwykUrls() {
    return getMediaStorage().swyk.uris.map(function (uri) { return changeMediaPrefix(uri); });
}
export function getSwyk() {
    return getSwykUrls().map(function (src) { return ({ src: src, id: src, updateTime: '2019-05-10 00:00:00' }); });
}
export function getMedias() {
    var media = getMediaStorage().media;
    Object.getOwnPropertyNames(media).forEach(function (key) {
        media[key].uri = changeMediaPrefix(media[key].uri);
    });
    return media;
}
export function getCorrectAnswer() {
    return getMedias()['correct_answer'].uri;
}
export function getPausePlayer() {
    return getMedias()['pause_player'].uri;
}
export function getStopPlayer() {
    return getMedias()['stop_player'].uri;
}
export function getStartPlaylist() {
    return getMedias()['start_playlist'].uri;
}
export function isGuid(str) {
    return str && str.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/) !== null;
}
export function fmtMsg(messageDescriptor, values) {
    return GLGlobal.intl.formatMessage(typeof messageDescriptor === 'string' ? { id: messageDescriptor } : messageDescriptor, values);
}
export function changeThemeColor(unit) {
    var portalTheme = document.querySelector('.portal-theme');
    if (!unit) {
        portalTheme.className = portalTheme.className.replace(/theme-(ls)?\d+/, '');
        return;
    }
    ;
    var themeClass = "theme-" + (unit > 0 ? unit : "ls" + Math.abs(unit));
    if (portalTheme.className.indexOf('theme-') > -1) {
        portalTheme.className = portalTheme.className.replace(/theme-(ls)?\d+/, themeClass);
    }
    else {
        portalTheme.className += " " + themeClass;
    }
}
export function isMobileOrTablet() {
    return (function (md) { return md.mobile() || md.tablet(); })(new MobileDetect(window.navigator.userAgent));
}
export function isMobile() {
    return bowser.mobile || (function (md) { return md.mobile(); })(new MobileDetect(window.navigator.userAgent));
}
export function isiPhone() {
    return bowser.iphone;
}
export function isIE() {
    return bowser.msie;
}
export function isEdge() {
    return bowser.msedge;
}
export function isSafari() {
    return bowser.safari;
}
export function isChrome() {
    return bowser.chrome;
}
export function isFirefox() {
    return bowser.firefox;
}
export function isChromeFirefox() {
    return isChrome() || isFirefox();
}
export function isAndroid() {
    return bowser.android;
}
export function isAndroidMobileChrome() {
    return isChrome() && isAndroid() && isMobile();
}
export function IEVersion() {
    var userAgent = navigator.userAgent;
    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1;
    var isEdge = userAgent.indexOf("Edge") > -1 && !isIE;
    var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return 7;
        }
        else if (fIEVersion == 8) {
            return 8;
        }
        else if (fIEVersion == 9) {
            return 9;
        }
        else if (fIEVersion == 10) {
            return 10;
        }
        else {
            return 6;
        }
    }
    else if (isEdge) {
        return 'edge';
    }
    else if (isIE11) {
        return 11;
    }
    else {
        return -1;
    }
}
export function encodeSig(url) {
    return url ? url.replace(/sig=(.+?)&/, function (m, p1) { return "sig=" + decodeURIComponent(p1).replace(/\+/gi, '%2B') + "&"; }) : url;
}
export function setParentChildrenCache(d) {
    var _a;
    var loginInfo = GLGlobal.loginInfo();
    if (loginInfo.loggedin) {
        var loginUserId = loginInfo.profile.sub;
        sessionStorage.setItem("parent-children", JSON.stringify((_a = {}, _a[loginUserId] = (d.self ? [d.self.id] : []).concat((d.children || []).map(function (c) { return c.id; })), _a)));
    }
}
export function getParentChildrenCache() {
    var loginInfo = GLGlobal.loginInfo();
    if (loginInfo.loggedin) {
        var loginUserId = loginInfo.profile.sub;
        return JSON.parse(sessionStorage.getItem("parent-children") || '{}')[loginUserId] || [];
    }
    return [];
}
export function promiseResolve(promise) {
    return new Promise(function (resolve, reject) {
        promise
            .then(function (d) {
            resolve(d);
        })
            .catch(function (e) {
            console.warn(e);
            reject(e);
        });
    });
}
function promiseTimeout(time) {
    if (time === void 0) { time = 0; }
    return new Promise(function (resolve, reject) {
        setTimeout(function () { return resolve('timeout'); }, time);
    });
}
function promiseRetry(_a) {
    var callback = _a.callback, _b = _a.timeout, timeout = _b === void 0 ? 5000 : _b, _c = _a.retryCount, retryCount = _c === void 0 ? 1 : _c;
    return Promise.race([
        callback(),
        promiseTimeout(timeout)
    ]).then(function (d) {
        if (d === 'timeout') {
            if (retryCount > 0) {
                return promiseRetry({ callback: callback, retryCount: --retryCount });
            }
            throw "timeout";
        }
        else {
            return Promise.resolve(d);
        }
    });
}
export function promiseAllSerial(promiseCallbacks) {
    var resultPromices = [];
    return promiseCallbacks.reduce(function (chain, callback) {
        return chain.then(function (d) {
            resultPromices.push(Promise.resolve(d));
            return promiseResolve(promiseRetry({ callback: callback }));
        });
    }, Promise.resolve({}))
        .then(function (d) {
        resultPromices.shift();
        resultPromices.push(Promise.resolve(d));
        return Promise.all(resultPromices);
    });
}
export function promiseAll(callbacks) {
    var promises = isAndroidMobileChrome()
        ? promiseAllSerial(callbacks)
        :
            Promise.all(callbacks.map(function (c) { return c(); }));
    return promises;
}
export function isKeepUser(userId) {
    var loginUser = GLGlobal.loginInfo().profile.sub;
    return { isKeeper: loginUser === userId, loginUser: loginUser };
}
export var maxTSILessonCount = 30;
export var maxTSIUnitCount = 30;
export function limitMaxUnit(unit) {
    var unitFormat = Number(unit);
    if (Number.isInteger(unitFormat)) {
        return unitFormat > maxTSIUnitCount ? maxTSIUnitCount : unitFormat;
    }
    return unit;
}
export function getMaxTSILessons(unit) {
    switch (unit) {
        case 21: return 18;
        case 22: return 12;
        case 23: return 12;
        case 24: return 14;
        case 25: return 16;
        case 26: return 16;
        case 27: return 13;
        case 28: return 14;
        case 29: return 12;
        case 30: return 15;
        default: return maxTSILessonCount; //1-20
    }
}
export function getMaxTSILessonMedias(unit) {
    switch (unit) {
        case 1: return 90;
        case 2: return 93;
        case 3: return 78;
        case 4: return 90;
        case 5: return 90;
        case 6: return 77;
        case 7: return 76;
        case 8: return 90;
        case 9: return 60;
        case 10: return 59;
        case 11: return 67;
        case 12: return 60;
        case 13: return 61;
        case 14: return 60;
        case 15: return 64;
        case 16: return 63;
        case 17: return 60;
        case 18: return 60;
        case 19: return 59;
        case 20: return 60;
        case 21: return 21;
        case 22: return 24;
        case 23: return 24;
        case 24: return 25;
        case 25: return 16;
        case 26: return 32;
        case 27: return 26;
        case 28: return 26;
        case 29: return 24;
        case 30: return 30;
        default: return 90;
    }
}
export function getSpecialRegions() {
    return GLGlobal.processEnv().specialRegions;
}
export function getJPRegion() {
    return getSpecialRegions().jp;
}
export function isJPRegion(id) {
    return id ? getJPRegion() === id.toUpperCase() : false;
}
export function getUSRegion() {
    return getSpecialRegions().us;
}
export function isUSRegion(id) {
    return id ? getUSRegion() === id.toUpperCase() : false;
}
// function swUpdateRefreshing() {
//     console.log('controllerchange')
//     navigator.serviceWorker.removeEventListener('controllerchange', swUpdateRefreshing)
//     window.location.reload();
// }
function registeSW(registe) {
    if (!registe) {
        unregister();
        return;
    }
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function () {
            // navigator.serviceWorker.addEventListener('controllerchange', swUpdateRefreshing);
            navigator.serviceWorker.register('/sw.js').then(function (registration) {
                registration.onupdatefound = function () {
                    var installingWorker = registration.installing;
                    if (installingWorker == null) {
                        return;
                    }
                    installingWorker.onstatechange = function () {
                        if (installingWorker.state === 'installed') {
                            if (navigator.serviceWorker.controller) {
                                console.log('installed');
                                window.location.reload();
                            }
                            else {
                                console.log('cached');
                            }
                        }
                    };
                };
            }).catch(function (error) {
                console.log('sw register failed: ', error);
            });
        });
    }
}
function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then(function (registration) {
            registration.unregister();
        })
            .catch(function (error) {
            console.log('sw unregister failed: ', error);
        });
    }
}
export function swRegister() {
    var workEnv = GLGlobal.workEnv();
    if (workEnv.isTest()) {
        registeSW(GLGlobal.processEnv().swRegister);
    }
}
export var getFormattedName = function (englishName, nativeName) {
    englishName = englishName ? englishName.trim() : englishName;
    nativeName = nativeName ? nativeName.trim() : nativeName;
    if (englishName &&
        englishName.length &&
        nativeName &&
        nativeName.length &&
        englishName.toLowerCase() !== nativeName.toLowerCase()) {
        return englishName + " (" + nativeName + ")";
    }
    else {
        return englishName && englishName.length ? englishName : nativeName;
    }
};
export var userHasRole = function (role, profile) {
    return (profile || GLGlobal.loginInfo().profile || { roles: [] }).roles.some(function (r) { return r == role; });
};
export function setChildrenRegion(data) {
    var _a;
    var loginInfo = GLGlobal.loginInfo();
    if (loginInfo.loggedin) {
        var loginUserId = loginInfo.profile.sub;
        sessionStorage.setItem("child-regions", JSON.stringify((_a = {}, _a[loginUserId] = data, _a)));
    }
    ;
}
