var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from "react";
import "./announcement-banner.less";
import { TeachingLocale } from "@app/locales/localeid";
import { fmtMsg } from "@app/util";
import { connect } from "gl-commonui";
import { Icon } from "antd-min";
import { setIsClosed } from "@app/states/retirement";
var AnnouncementBanner = /** @class */ (function (_super) {
    __extends(AnnouncementBanner, _super);
    function AnnouncementBanner(props) {
        var _this = _super.call(this, props) || this;
        _this.closeBanner = _this.closeBanner.bind(_this);
        return _this;
    }
    AnnouncementBanner.prototype.closeBanner = function () {
        this.props.setIsClosed(true);
    };
    AnnouncementBanner.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (this.props.loginInfo !== prevProps.loginInfo && this.props.loginInfo.loggedin === false) {
            this.props.setIsClosed(false);
        }
    };
    AnnouncementBanner.prototype.render = function () {
        return (this.props.isClosed ? null :
            React.createElement("div", { className: "announcement-banner" },
                React.createElement("div", null, fmtMsg(TeachingLocale.CommonAnnouncementRetirement)),
                React.createElement("a", { href: "javascript:void(0)", onClick: this.closeBanner },
                    React.createElement(Icon, { type: "close" }))));
    };
    AnnouncementBanner = __decorate([
        connect(function (_a) {
            var loginInfo = _a.oidc.loginInfo, isClosed = _a.retirement.isClosed;
            return ({
                loginInfo: loginInfo,
                isClosed: isClosed
            });
        }, { setIsClosed: setIsClosed })
    ], AnnouncementBanner);
    return AnnouncementBanner;
}(React.Component));
export { AnnouncementBanner };
