import * as React from 'react';
import { PathConfig } from '../config/pathconfig';
import { UserAvatar, LanguageIcon, DrawerHelper } from 'gl-commonui';
import LeftHeader from '@app/components/header/leftheader';
import RightHeader from '@app/components/header/rightheader';
import { TeachingLocale } from '@app/locales/localeid';
function contentHeaderMobile(isStudent) {
    return (React.createElement("div", { className: "header-mobile" },
        React.createElement("div", { className: "header-mobile-top" },
            React.createElement(UserAvatar, { hideEditProfile: isStudent, pageAfterSignin: PathConfig.Home, pageAfterSignout: isStudent ? PathConfig.CodeLogin : PathConfig.Home }),
            React.createElement(LanguageIcon, null)),
        React.createElement(RightHeader, null)));
}
var defineMasterPage = function (isStudent) {
    var masterPagesDefined = {
        appName: TeachingLocale.CommonSiteTitle,
        back: {
            props: {
                headerComponents: {
                    left: React.createElement(LeftHeader, null),
                    right: [
                        React.createElement(DrawerHelper, { componentItems: contentHeaderMobile(isStudent) }),
                        React.createElement(RightHeader, null,
                            React.createElement(LanguageIcon, null),
                            React.createElement(UserAvatar, { hideEditProfile: isStudent, pageAfterSignin: PathConfig.Home, pageAfterSignout: isStudent ? PathConfig.CodeLogin : PathConfig.Home }))
                    ]
                },
                styleClass: {
                    entirety: {
                        class: 'portal-theme'
                    }
                },
            }
        }
    };
    return masterPagesDefined;
};
export { defineMasterPage };
