var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
var CloseEyeIcon = /** @class */ (function (_super) {
    __extends(CloseEyeIcon, _super);
    function CloseEyeIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CloseEyeIcon.prototype.render = function () {
        return (React.createElement("svg", { className: "icon", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 45 32" },
            React.createElement("circle", { className: "path", cx: "23", cy: "16", r: "16" }),
            React.createElement("g", { id: "close-eye", transform: "matrix(1 0 0 1 7 1)", stroke: "none", "stroke-width": "1", fill: "none", "fill-rule": "evenodd" },
                React.createElement("path", { d: "M26.4072747,12.6178907 C26.6365562,12.2739929 26.5601208,11.8154545 26.2544285,11.5479675 C25.9105308,11.2804806 25.4519923,11.356916 25.1845054,11.7008137 C25.1462754,11.7390191 20.9048129,16.7447615 15.860816,16.7447615 C10.9697389,16.7447615 6.5371511,11.7008137 6.4989457,11.6626083 C6.23143423,11.356916 5.7347149,11.3187106 5.42899803,11.5861729 C5.12330571,11.8536599 5.08510031,12.3504037 5.35256267,12.6561206 C5.42899803,12.7707368 6.34607499,13.8024545 7.79810123,14.948813 L5.849282,16.974043 C5.54358968,17.2797354 5.58181964,17.7764792 5.88751196,18.0439662 C5.96394731,18.1968123 6.15499887,18.2732477 6.34607499,18.2732477 C6.537102,18.2732477 6.72817811,18.1968123 6.88102427,18.0439662 L8.98266511,15.8659145 C9.97617743,16.5537345 11.1607413,17.2033491 12.4599459,17.661863 L11.6574852,20.3749131 C11.542869,20.7952216 11.7721505,21.2155547 12.192459,21.3301954 L12.421716,21.3301954 C12.7656137,21.3301954 13.0713306,21.1009139 13.1477414,20.7570162 L13.9501776,18.0439662 C14.5615622,18.1586069 15.2111768,18.2350423 15.8607669,18.2350423 C16.5103569,18.2350423 17.1599715,18.1586069 17.7713807,18.0439662 L18.5737923,20.7188108 C18.6502523,21.0627085 18.99415,21.2919655 19.2998423,21.2919655 C19.3762777,21.2919655 19.4526885,21.2919655 19.4909184,21.2537601 C19.9112269,21.1390948 20.1405085,20.7188108 20.0258922,20.2984777 L19.2234315,17.6236331 C20.5226607,17.1650701 21.7072,16.51548 22.7007123,15.8276846 L24.7641478,17.9675308 C24.9169939,18.120377 25.1080455,18.1968123 25.299097,18.1968123 C25.4901486,18.1968123 25.6812247,18.120377 25.8340463,17.9675554 C26.1397386,17.6618385 26.1397386,17.2033246 25.8722763,16.8976077 L23.9234571,14.8724022 C25.4901732,13.7260192 26.4072992,12.6178907 26.4072992,12.6178907 L26.4072747,12.6178907 Z", id: "closeeye", fill: "white", "fill-rule": "nonzero" }))));
    };
    return CloseEyeIcon;
}(Component));
export default CloseEyeIcon;
