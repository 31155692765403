var TeachingLocale = /** @class */ (function () {
    function TeachingLocale() {
    }
    TeachingLocale.LessonMsgComing = "lesson.msg.coming";
    TeachingLocale.MsgWarningUnit = "msg.warning.unit";
    TeachingLocale.MsgWarningLimitUnit = "msg.warning.limitunit";
    TeachingLocale.MsgWarningNoMedia = "msg.warning.nomedia";
    TeachingLocale.HomeChildSchool = "home.child.school";
    TeachingLocale.HomeChildClass = "home.child.class";
    TeachingLocale.TSICopyRight = "tsi.material.copyright";
    TeachingLocale.REPOnlineText = "common.link.repOnline";
    TeachingLocale.ChildrenWelcomeTitle = "children.welcome.title";
    TeachingLocale.ChildrenSelectTitle = "children.select.title";
    TeachingLocale.ChildrenExtendedSubscription = "children.extended.subscription";
    TeachingLocale.ConcurrencyMessageLine_1 = "concurrency.message.line_1";
    TeachingLocale.ConcurrencyMessageLine_2 = "concurrency.message.line_2";
    TeachingLocale.ConcurrencyStartButton = "concurrency.start_button";
    TeachingLocale.MediaplayerMessageAutoplayalert = "mediaplayer.message.autoplayalert";
    TeachingLocale.CommonSiteTitle = "common.site.title";
    TeachingLocale.ChildrenNoneUnit = "children.none.unit";
    TeachingLocale.CommonAnnouncementRetirement = "common.announcement.retirement";
    return TeachingLocale;
}());
export { TeachingLocale };
