var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { Component } from "react";
import { withRouter, GLUtil, maskThrottle, unmaskThrottle, GLGlobal, RoleName } from "gl-commonui";
import { lazyInject, TYPES, limitMaxUnit, isKeepUser, promiseAll, getMaxTSILessonMedias, userHasRole } from "@app/util";
import UnitCard from "./components/unitcard";
import HeartBeat from "@app/components/heartbeat";
var UnitCoaches = ["Ms. Jodie", "Ms. Natalie", "Ms. Abby", "Mr. Dan", "Mr. Adam", "Mr. Presley", "Ms. Julie", "Ms. Alex", "Mr. Sawin", "Mr. Jake", "Ms. Samantha", "Mr. Jake", "Ms. Paula", "Mr. Andrew", "Ms. Jodie", "Ms. Judy", "Mr. David", "Ms. Marina & Helen", "Mr. Adam", "Mr. Sawin", "Mr. Nathan", "Mr. Miklos", "Ms. Judy", "Ms. Tiffany", "Mr. Tim", "Ms. Linda", "Ms. Jeanie", "Mr. Julien", "Mr. Miklos", "Mr. David"];
var UnitsPage = /** @class */ (function (_super) {
    __extends(UnitsPage, _super);
    function UnitsPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        var from = GLUtil.queryParse().from;
        var userId = _this.props.match.params.userId;
        _this.state = {
            from: from,
            userId: userId,
            versionGroupId: null,
            unit: 0,
            lsVersionGroupId: null,
            lsUnit: 0,
            units: []
        };
        return _this;
    }
    UnitsPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userId, isKeeper, isStudent, _a, stuAccessUnit, unitBadges, parentAccessUnit, versionId, _b, units, unit, lsUnit, versionGroupId, _loop_1, this_1, index;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        maskThrottle();
                        userId = this.state.userId;
                        isKeeper = isKeepUser(userId).isKeeper;
                        isStudent = userHasRole(RoleName.student);
                        return [4 /*yield*/, promiseAll([
                                function () { return isKeeper && !isStudent ? Promise.resolve({}) : _this.tsiService.getStudentAccessUnit({ id: userId }); },
                                function () { return isKeeper ? Promise.resolve([]) : _this.tsiLogService.getUnitBadges({ studentId: userId }); },
                                function () { return isKeeper && !isStudent ? _this.accessunitService.getAccessUnit({ parentId: userId }) : Promise.resolve({}); },
                            ])];
                    case 1:
                        _a = __read.apply(void 0, [_c.sent(), 3]), stuAccessUnit = _a[0], unitBadges = _a[1], parentAccessUnit = _a[2];
                        versionId = null;
                        if ((stuAccessUnit.versionGroupId && stuAccessUnit.unit)
                            ||
                                (stuAccessUnit.lsVersionGroupId && stuAccessUnit.lsUnit)) {
                            versionId = stuAccessUnit.versionGroupId;
                            this.setState({
                                versionGroupId: stuAccessUnit.versionGroupId,
                                unit: limitMaxUnit(stuAccessUnit.unit),
                                lsVersionGroupId: stuAccessUnit.lsVersionGroupId,
                                lsUnit: stuAccessUnit.lsUnit,
                            });
                        }
                        else if (GLGlobal.loginInfo().profile.sub === this.props.match.params.userId) {
                            versionId = parentAccessUnit.versionGroupId;
                            this.setState({
                                versionGroupId: parentAccessUnit.versionGroupId,
                                unit: limitMaxUnit(parentAccessUnit.unit),
                                lsVersionGroupId: parentAccessUnit.lsVersionGroupId,
                                lsUnit: parentAccessUnit.lsUnit,
                            });
                        }
                        else {
                            unmaskThrottle();
                            return [2 /*return*/];
                        }
                        _b = this.state, units = _b.units, unit = _b.unit, lsUnit = _b.lsUnit;
                        if (unit && unit > 0) {
                            versionGroupId = this.state.versionGroupId;
                            _loop_1 = function (index) {
                                var percentage = (unitBadges.find(function (badge) { return badge.unit == index; }) || { count: 0 }).count / getMaxTSILessonMedias(unit);
                                units.push({ id: index, index: index, name: "Unit " + index, coachName: UnitCoaches[index - 1], coachAvatar: this_1.getCoachAvatar(index), versionId: versionGroupId, percentage: percentage });
                            };
                            this_1 = this;
                            for (index = 1; index <= unit; index++) {
                                _loop_1(index);
                            }
                        }
                        this.setState({ units: units });
                        unmaskThrottle();
                        return [2 /*return*/];
                }
            });
        });
    };
    UnitsPage.prototype.getCoachAvatar = function (index) {
        var coachName = UnitCoaches[index - 1];
        var coachNameSegments = coachName.split(' ');
        var name = coachName.includes('&') ? coachNameSegments.slice(1).filter(function (s) { return s && s != '&'; }).join('-') : coachNameSegments.pop();
        return "coach-" + name;
    };
    UnitsPage.prototype.render = function () {
        var _this = this;
        var _a = this.state, from = _a.from, units = _a.units;
        var history = this.props.history;
        return React.createElement("div", { className: "units" },
            React.createElement(HeartBeat, { userId: this.state.userId },
                React.createElement("div", { className: "cards" }, units && units.length > 0 && units.map(function (unit) { return (React.createElement("div", { key: "base_" + unit.id, className: "blinkable" },
                    React.createElement(UnitCard, { key: unit.id, history: history, to: from, userId: _this.state.userId, unitInfo: unit, isShowBadge: unit.percentage > 0, percentage: unit.percentage }))); }))));
    };
    __decorate([
        lazyInject(TYPES.IAccessUnitService)
    ], UnitsPage.prototype, "accessunitService", void 0);
    __decorate([
        lazyInject(TYPES.ITSILogService)
    ], UnitsPage.prototype, "tsiLogService", void 0);
    __decorate([
        lazyInject(TYPES.ITSIService)
    ], UnitsPage.prototype, "tsiService", void 0);
    UnitsPage = __decorate([
        withRouter
    ], UnitsPage);
    return UnitsPage;
}(Component));
export default UnitsPage;
