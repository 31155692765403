var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import "./style/full-screen.less";
import { PlayAction } from "@app/components/media/mediaplayer";
import FullScreenIcon from "./full-screen-icon";
import ExitFullScreenIcon from "./exit-full-screen-icon";
import { isiPhone } from "@app/util";
export var STATE_SAVE_KEY = "data-fullscreen-state";
var FullScreenButton = /** @class */ (function (_super) {
    __extends(FullScreenButton, _super);
    function FullScreenButton(props) {
        var _this = _super.call(this, props) || this;
        _this.goToFullScreen = function () {
            var elem = document.querySelector('.contentplayer');
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            }
            else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            }
            else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            }
            else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
            _this.setState({ state: PlayAction.exitFullscreen });
            _this.props.onFullScreen && _this.props.onFullScreen();
        };
        _this.exitFullscreen = function () {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
            else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
            else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            }
            else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            _this.setState({ state: PlayAction.fullscreen });
            _this.props.onExitFullScreen && _this.props.onExitFullScreen();
        };
        _this.fullscreen = function () {
            if (document.fullscreenEnabled ||
                document.webkitFullscreenEnabled ||
                document.mozFullScreenEnabled ||
                document.msFullscreenEnabled) {
                if (document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement ||
                    document.msFullscreenElement) {
                    _this.exitFullscreen();
                }
                else {
                    _this.goToFullScreen();
                }
            }
            else {
                console.log('Fullscreen is not supported on your browser.');
            }
        };
        var state = PlayAction.fullscreen;
        var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement ||
            document.webkitFullscreenElement || document.msFullscreenElement;
        if (fullscreenElement == null) {
            state = PlayAction.fullscreen;
        }
        else {
            state = PlayAction.exitFullscreen;
        }
        _this.state =
            {
                state: state,
            };
        return _this;
    }
    FullScreenButton.prototype.componentDidMount = function () {
        var _this = this;
        document.onfullscreenchange = document.onmozfullscreenchange = document.onwebkitfullscreenchange = document.onmsfullscreenchange = function (ev) {
            var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement ||
                document.webkitFullscreenElement || document.msFullscreenElement;
            if (fullscreenElement == null) {
                _this.setState({ state: PlayAction.fullscreen });
            }
            else {
                _this.setState({ state: PlayAction.exitFullscreen });
            }
        };
    };
    FullScreenButton.prototype.componentWillUnmount = function () {
        document.onfullscreenchange = document.onmozfullscreenchange = document.onwebkitfullscreenchange = document.onmsfullscreenchange = null;
    };
    FullScreenButton.prototype.render = function () {
        if (!isiPhone()) {
            return (React.createElement("div", { className: "flat-button", onClick: this.fullscreen },
                this.state.state == PlayAction.fullscreen && React.createElement(FullScreenIcon, null),
                this.state.state == PlayAction.exitFullscreen && React.createElement(ExitFullScreenIcon, null)));
        }
        return (null);
    };
    return FullScreenButton;
}(Component));
export default FullScreenButton;
