import * as moment from 'moment';
import { GLGlobal, LanguageDateFormat } from 'gl-commonui';
import { PlayerRole } from '.';
var DateHelper = /** @class */ (function () {
    function DateHelper() {
    }
    DateHelper.formatDate2Local = function (date) {
        if (!date)
            return '';
        return DateHelper.toLocalMoment(date).format(LanguageDateFormat[GLGlobal.intl.locale]);
    };
    DateHelper.formatDate = function (date, format) {
        return DateHelper.toLocalMoment(date).format(format);
    };
    DateHelper.toLocalMoment = function (date) {
        var localTime = moment.utc(date).toDate();
        return moment(localTime);
    };
    return DateHelper;
}());
export { DateHelper };
var RoleHelper = /** @class */ (function () {
    function RoleHelper() {
    }
    RoleHelper.isStudent = function (role) {
        if (role == PlayerRole.Text || role == PlayerRole.Digital || role == PlayerRole.Dual) {
            return true;
        }
        else {
            return false;
        }
    };
    return RoleHelper;
}());
export { RoleHelper };
