var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import "./style/index.less";
import { isKeepUser } from "@app/util";
import { GLUtil } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
var FastwardsStep = 30;
export function Fastwards(_a) {
    var progress = _a.progress, time = _a.time, seek = _a.seek;
    var _b = __read(useState(true), 2), limit = _b[0], setSeekLimit = _b[1];
    useEffect(function () {
        setSeekLimit(isSeekLimitByMaxProgress());
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(FastwardsWrapper, { disabled: isBeginDisabled(time), onClick: function () { seek(getBackwards(time)); } },
            React.createElement(FastBackwardsIcon, null)),
        React.createElement(FastwardsWrapper, { isForwards: true, disabled: (limit && isForwardsDisabled(progress, time)) || isBeginDisabled(time), onClick: function () { seek(getForwards(progress, time, limit)); } },
            React.createElement(FastForwardsIcon, null))));
}
function isSeekLimitByMaxProgress() {
    var userId = GLUtil.pathParse(PathConfig.LessonPlayer).userId;
    var isKeeper = isKeepUser(userId).isKeeper;
    return !isKeeper;
}
function isBeginDisabled(time) {
    return time < 1;
}
function getBackwards(time) {
    return time <= FastwardsStep ? 0 : (time - FastwardsStep);
}
function getForwards(progress, time, limit) {
    return limit && (progress - time <= FastwardsStep) ? progress : (time + FastwardsStep);
}
function isForwardsDisabled(progress, time) {
    return time >= progress;
}
function FastwardsWrapper(_a) {
    var disabled = _a.disabled, onClick = _a.onClick, _b = _a.isForwards, isForwards = _b === void 0 ? false : _b, children = _a.children;
    return (React.createElement("div", { className: "fastwards " + (isForwards ? "forwards" : "") + " " + (disabled ? "disabled" : ""), onClick: function (e) {
            e.stopPropagation();
            !disabled && onClick();
        } }, children));
}
function FastBackwardsIcon() {
    return (React.createElement("svg", { width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 1024 1024" },
        React.createElement("path", { d: "M214.74304 535.86944L444.1088 765.07136a68.07552 68.07552 0 0 0 48.27136 19.99872 53.68832 53.68832 0 0 0 53.76-53.73952l0-137.1648 171.03871999 170.9056a68.05504 68.05504 0 0 0 48.26112001 19.99872 53.68832 53.68832 0 0 0 53.77024-53.73952l0-438.70208001a53.73952 53.73952 0 0 0-53.77024-53.69855999l-0.43008 0a67.92192 67.92192 0 0 0-48.27135999 19.99872l-170.59840001 170.46528 0-136.76544a53.72928 53.72928 0 0 0-53.76-53.69856l-0.44032 0a67.8912 67.8912 0 0 0-48.26112 19.99872l-228.93568 228.74112a34.17088 34.17088 0 0 0 0 48.19968z", "p-id": "5476" })));
}
function FastForwardsIcon() {
    return (React.createElement("svg", { width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 1024 1024" },
        React.createElement("path", { d: "M214.74304 535.86944L444.1088 765.07136a68.07552 68.07552 0 0 0 48.27136 19.99872 53.68832 53.68832 0 0 0 53.76-53.73952l0-137.1648 171.03871999 170.9056a68.05504 68.05504 0 0 0 48.26112001 19.99872 53.68832 53.68832 0 0 0 53.77024-53.73952l0-438.70208001a53.73952 53.73952 0 0 0-53.77024-53.69855999l-0.43008 0a67.92192 67.92192 0 0 0-48.27135999 19.99872l-170.59840001 170.46528 0-136.76544a53.72928 53.72928 0 0 0-53.76-53.69856l-0.44032 0a67.8912 67.8912 0 0 0-48.26112 19.99872l-228.93568 228.74112a34.17088 34.17088 0 0 0 0 48.19968z", "p-id": "5476" })));
}
