var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { lazyInject, TYPES, userHasRole } from "@app/util";
import { OidcProxy, PathConfig as GLCommonPathConfig, RoleName, } from "gl-commonui";
var LessonVideosAccess = /** @class */ (function () {
    function LessonVideosAccess() {
    }
    LessonVideosAccess.prototype.invoke = function (context) {
        LessonVideosRouteGuard(this.next, context, this.tsiService, this.studentAppService);
    };
    __decorate([
        lazyInject(TYPES.ITSIService)
    ], LessonVideosAccess.prototype, "tsiService", void 0);
    __decorate([
        lazyInject(TYPES.IStudentAppService)
    ], LessonVideosAccess.prototype, "studentAppService", void 0);
    return LessonVideosAccess;
}());
export { LessonVideosAccess };
function LessonVideosRouteGuard(next, context, tsiService, studentAppService) {
    return __awaiter(this, void 0, void 0, function () {
        var isStudent, loggedInUser, userId, isStudent_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, OidcProxy.getUser().then(function (loginInfo) { return loginInfo.loggedin && userHasRole(RoleName.student, loginInfo.profile); })];
                case 1:
                    isStudent = _a.sent();
                    if (!isStudent) return [3 /*break*/, 3];
                    return [4 /*yield*/, studentAppService.getTsiAccess()
                            .then(function (result) {
                            if (result && result.studentsTSIEnbaled && result.studentsTSIEnbaled.length) {
                                next.invoke(context);
                            }
                            else {
                                context.history.replace({ pathname: GLCommonPathConfig.AccessDenied });
                            }
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 3: return [4 /*yield*/, OidcProxy.getUser().then(function (loginInfo) { return loginInfo.profile; })];
                case 4:
                    loggedInUser = _a.sent();
                    userId = context.match.params.userId;
                    isStudent_1 = loggedInUser.sub !== userId;
                    return [4 /*yield*/, tsiService.isVTSIAllowed({ userId: userId, isStudent: isStudent_1 })
                            .then(function (result) {
                            if (result) {
                                next.invoke(context);
                            }
                            else {
                                context.history.replace({ pathname: GLCommonPathConfig.AccessDenied });
                            }
                        })];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    });
}
