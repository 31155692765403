import { bindingCollection } from 'gl-commonui';
import { TYPES } from './types';
import { ResourceService } from './resource/index';
import { AccessUnitService } from './app/accessunit';
import { SettingService } from './app/setting';
import { StudentAppService } from './app/student';
import { HeartBeatService } from './app/heartbeat';
import { TSIService } from './tsi';
import { TSILogService } from './tsilog';
bindingCollection.push(function (container) {
    container.bind(TYPES.IAccessUnitService).to(AccessUnitService);
    container.bind(TYPES.ISettingService).to(SettingService);
    container.bind(TYPES.IResourceService).to(ResourceService);
    container.bind(TYPES.IHeartBeatService).to(HeartBeatService);
    container.bind(TYPES.ITSIService).to(TSIService);
    container.bind(TYPES.ITSILogService).to(TSILogService);
    container.bind(TYPES.IStudentAppService).to(StudentAppService);
});
