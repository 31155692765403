var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from "react";
import { withRouter, GLGlobal, GLUtil, NotificationType, MessageHelper, LanguageDateFormat, } from "gl-commonui";
import { lazyInject, TYPES, PlayerRole, setParentChildrenCache, limitMaxUnit, fmtMsg, LicenseType, getFormattedName } from "@app/util";
import { Card, Spin, Avatar as UserAvatar } from "antd-min";
import { PathConfig } from "@app/config/pathconfig";
import { themeChangedDispatch } from "@app/states/theme";
import { heartbeat_unregister_state_change } from "@app/app";
import { TeachingLocale } from "@app/locales/localeid";
import { CopyRightBanner } from "@app/components/copyright-banner";
import moment from "moment";
import { Pie } from "@app/style/svg";
var ChildrenPage = /** @class */ (function (_super) {
    __extends(ChildrenPage, _super);
    function ChildrenPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = {
            currentUser: { name: '' },
            isParent: false,
            children: [],
            self: {},
            loadFinished: false
        };
        return _this;
    }
    ChildrenPage.prototype.componentDidMount = function () {
        var _this = this;
        var _a = GLGlobal.loginInfo().profile, parentId = _a.sub, name = _a.name, roles = _a.roles, avatarUrl = _a.avatarUrl;
        this.setState({ currentUser: { name: name, avatarUrl: avatarUrl }, isParent: roles.some(function (r) { return r === 'Parent'; }) });
        this.tsiService.getUserInfos({ parentId: parentId }, { includeAvatar: true }).then(function (d) {
            _this.setState({
                children: d.children
                    .filter(function (child) { return child.licenseType == LicenseType.GrapeSEED; })
                    .sort(function (a, b) { return a.name > b.name ? 1 : -1; }), self: d.self ? __assign({}, d.self, { id: parentId, englishName: name, avatar: avatarUrl }) : null, loadFinished: true
            });
            setParentChildrenCache(d);
        });
        heartbeat_unregister_state_change();
    };
    ChildrenPage.prototype.render = function () {
        var _a = this.state, children = _a.children, self = _a.self, currentUser = _a.currentUser, isParent = _a.isParent, loadFinished = _a.loadFinished;
        var history = this.props.history;
        return React.createElement("div", { className: "children-container" },
            React.createElement("div", { className: "children" },
                React.createElement("div", { className: "welcome" },
                    React.createElement("h1", null, GLGlobal.intl.formatMessage({ id: TeachingLocale.ChildrenWelcomeTitle }, { user: currentUser.name })),
                    React.createElement("h3", null, GLGlobal.intl.formatMessage({ id: TeachingLocale.ChildrenSelectTitle })),
                    children.length > 0 && React.createElement("hr", null)),
                React.createElement(Spin, { spinning: !loadFinished, delay: 0 },
                    React.createElement("div", { className: "cards" },
                        self ? React.createElement(Card, { key: '0', className: 'not-child', hoverable: true, onClick: function () { return navigateBy(history, self, PlayerRole.Other, self, TeachingLocale.MsgWarningUnit, TeachingLocale.MsgWarningLimitUnit); } },
                            React.createElement(Card.Meta, { avatar: React.createElement(UserAvatar, { className: "default-avatar", icon: "user", src: currentUser.avatarUrl }), title: React.createElement("span", { title: currentUser.name }, currentUser.name) })) : null,
                        children && children
                            .map(function (child) { return (React.createElement(Card, { key: child.id, className: isParent ? '' : 'not-child', hoverable: true, onClick: function () { return navigateBy(history, child, child.subscriptionType, self, TeachingLocale.MsgWarningUnit, TeachingLocale.MsgWarningLimitUnit); } },
                            React.createElement(Card.Meta, { avatar: React.createElement(UserAvatar, { className: "default-avatar", icon: "user", src: child.avatar }), title: React.createElement("span", { title: child.name }, getFormattedName(child.englishName, child.name)), description: !child.isExtendedSubscription && React.createElement("div", { className: 'user-info' },
                                    React.createElement("div", null,
                                        React.createElement("span", null,
                                            fmtMsg(TeachingLocale.HomeChildSchool),
                                            " "),
                                        React.createElement("div", { title: child.schoolName }, child.schoolName)),
                                    React.createElement("div", null,
                                        React.createElement("span", null,
                                            fmtMsg(TeachingLocale.HomeChildClass),
                                            " "),
                                        React.createElement("div", { title: child.schoolClassName }, child.schoolClassName))) }),
                            child.extendedSubscriptionEndDate &&
                                React.createElement("div", { className: "gs-graceperiod" },
                                    React.createElement(Pie, null),
                                    React.createElement("div", { className: "gs-graceperiod__text" },
                                        React.createElement("span", null, fmtMsg(TeachingLocale.ChildrenExtendedSubscription)),
                                        React.createElement("span", null, moment(child.extendedSubscriptionEndDate).format(LanguageDateFormat[GLGlobal.intl.locale])))))); })))),
            React.createElement(CopyRightBanner, null));
    };
    __decorate([
        lazyInject(TYPES.ITSIService)
    ], ChildrenPage.prototype, "tsiService", void 0);
    ChildrenPage = __decorate([
        withRouter
    ], ChildrenPage);
    return ChildrenPage;
}(Component));
export default ChildrenPage;
export function navigateBy(history, user, role, parent, message, lsUnitLimitMsg) {
    return __awaiter(this, void 0, void 0, function () {
        var userId, name, unit, lsUnit, versionGroupId, lsVersionGroupId, maxUnit, targetUnit, unitVersionGroudId;
        return __generator(this, function (_a) {
            userId = user.id, name = user.name, unit = user.unit, lsUnit = user.lsUnit, versionGroupId = user.versionGroupId, lsVersionGroupId = user.lsVersionGroupId;
            parent = parent || { id: GLGlobal.loginInfo().profile.sub };
            maxUnit = unit ? unit : lsUnit;
            targetUnit = limitMaxUnit(maxUnit);
            unitVersionGroudId = maxUnit > 0 ? versionGroupId : lsVersionGroupId;
            if (targetUnit < 0) {
                MessageHelper.Message(NotificationType.Warning, fmtMsg(lsUnitLimitMsg));
                return [2 /*return*/];
            }
            if (targetUnit && unitVersionGroudId) {
                history.push({ pathname: GLUtil.pathStringify(PathConfig.Lessons, { userId: userId, unit: targetUnit, versionId: unitVersionGroudId }) });
                themeChangedDispatch({
                    leftData: {
                        id: userId, role: role, src: user.avatar, name: user.name, unit: unit, lsUnit: lsUnit, parentId: parent.id,
                        isLoaded: true
                    }
                });
            }
            else {
                MessageHelper.Message(NotificationType.Warning, fmtMsg(message));
            }
            return [2 /*return*/];
        });
    });
}
