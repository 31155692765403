import { LocaleService } from "@app/service/locale";
import { PrimaryLanguageLocale } from "gl-commonui";
export function intlLoaded(locale) {
    var service = new LocaleService();
    return new Promise(function (resolve, reject) {
        switch (locale.locale) {
            case PrimaryLanguageLocale.Arabic:
                service
                    .getLocaleJson(PrimaryLanguageLocale.Arabic)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./ar-sa.json"));
                    }, "ar-sa.json");
                });
                break;
            case PrimaryLanguageLocale.Chinese:
                service
                    .getLocaleJson(PrimaryLanguageLocale.Chinese)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./zh-cn.json"));
                    }, "zh-cn.json");
                });
                break;
            case PrimaryLanguageLocale.Russian:
                service
                    .getLocaleJson(PrimaryLanguageLocale.Russian)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./ru.json"));
                    }, "ru.json");
                });
                break;
            case PrimaryLanguageLocale.Vietnamese:
                service
                    .getLocaleJson(PrimaryLanguageLocale.Vietnamese)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./vi.json"));
                    }, "vi.json");
                });
                break;
            case PrimaryLanguageLocale.Mongolian:
                service
                    .getLocaleJson(PrimaryLanguageLocale.Mongolian)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./mn.json"));
                    }, "mn.json");
                });
                break;
            case PrimaryLanguageLocale.Malaysia:
                service
                    .getLocaleJson(PrimaryLanguageLocale.Malaysia)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./ms.json"));
                    }, "ms.json");
                });
                break;
            case PrimaryLanguageLocale.Korean:
                service
                    .getLocaleJson(PrimaryLanguageLocale.Korean)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./ko.json"));
                    }, "ko.json");
                });
                break;
            case PrimaryLanguageLocale.Japanese:
                service
                    .getLocaleJson(PrimaryLanguageLocale.Japanese)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./ja.json"));
                    }, "ja.json");
                });
                break;
            case PrimaryLanguageLocale.Spanish:
                service
                    .getLocaleJson(PrimaryLanguageLocale.Spanish)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./es.json"));
                    }, "es.json");
                });
                break;
            case PrimaryLanguageLocale.Thai:
                service
                    .getLocaleJson(PrimaryLanguageLocale.Thai)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./th.json"));
                    }, "th.json");
                });
                break;
            case PrimaryLanguageLocale.Myanmar:
                service
                    .getLocaleJson(PrimaryLanguageLocale.Myanmar)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./my.json"));
                    }, "my.json");
                });
                break;
            default:
                service
                    .getLocaleJson(PrimaryLanguageLocale.English)
                    .then(resolve)
                    .catch(function () {
                    require.ensure([], function () {
                        resolve(require("./en.json"));
                    }, "en.json");
                });
                break;
        }
        localeUISupport(locale);
    });
}
function localeUISupport(locale) {
    var container = document.body;
    var classes = container.className.split(' ');
    classes.forEach(function (className) {
        if (className && className.indexOf("lang-") === 0) {
            container.classList.remove(className);
        }
    });
    container.classList.add("lang-" + locale.locale);
}
