import React from "react";
import { Card } from "antd-min";
import { PathConfig } from "@app/config/pathconfig";
import { GLUtil, maskThrottle, unmaskThrottle, GLGlobal, NotificationType, MessageHelper } from "gl-commonui";
import { changeThemeColor, fmtMsg, isSafari, isIE } from "@app/util/func";
import Badge, { BadgeType } from "@app/components/badge";
import { RoleHelper } from "@app/util";
import { TeachingLocale } from "@app/locales/localeid";
var isSupportWebP = !(isSafari() || isIE());
export default function UnitCard(_a) {
    var history = _a.history, to = _a.to, userId = _a.userId, unitInfo = _a.unitInfo, isShowBadge = _a.isShowBadge, percentage = _a.percentage;
    var unitClassName = null;
    if (unitInfo.id > 0) {
        unitClassName = "unit-" + unitInfo.index;
    }
    else {
        unitClassName = "ls-" + unitInfo.index;
    }
    return (React.createElement("div", { className: "unit-base" },
        React.createElement(Card, { hoverable: true, onClick: function () {
                navigateBy(history, to, userId, unitInfo, TeachingLocale.MsgWarningUnit);
                changeThemeColor(unitInfo.id);
            }, cover: React.createElement("div", { className: "back unit " + unitClassName }) }),
        React.createElement("div", { className: 'coach-info' },
            React.createElement("div", { className: 'coach-avatar' },
                React.createElement("div", { className: "coach-layout " + (isSupportWebP ? 'coach-webp' : 'coach-img') + " " + unitInfo.coachAvatar })),
            React.createElement("div", null,
                React.createElement("span", { className: "unit-text" }, unitInfo.name),
                React.createElement("span", { className: "unit-text" }, unitInfo.coachName.replace(/-/g, ' ')))),
        isShowBadge &&
            React.createElement("div", { className: "badge-container" },
                React.createElement(Badge, { percentage: percentage, type: BadgeType.Large }))));
}
function navigateBy(history, to, userId, unitInfo, message) {
    var path = PathConfig.Lessons;
    if (unitInfo.id > 0) {
        switch (to) {
            case "library":
                path = PathConfig.Library;
                break;
            case "listen":
                path = PathConfig.Listen;
                break;
            default:
                path = PathConfig.Lessons;
                break;
        }
    }
    if (unitInfo.id && unitInfo.versionId) {
        maskThrottle();
        if (GLGlobal.store.getState().theme.leftData.role && !RoleHelper.isStudent(GLGlobal.store.getState().theme.leftData.role)) {
        }
        unmaskThrottle();
        history.push({ pathname: GLUtil.pathStringify(path, { userId: userId, unit: unitInfo.id, versionId: unitInfo.versionId }) });
    }
    else {
        MessageHelper.Message(NotificationType.Warning, fmtMsg(message));
    }
}
