var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { Component } from "react";
import { withRouter, GLUtil, maskThrottle, unmaskThrottle, connect } from "gl-commonui";
import { PathConfig } from '@app/config/pathconfig';
import { lazyInject, TYPES, ContentGroupEnum, maxTSIUnitCount, isKeepUser, getMaxTSILessons, limitMaxUnit, promiseAll } from "@app/util";
import ContentPlayer from "@app/components/media/contentplayer";
import CountDownControlContainer from "@app/components/media/countdown-controlcontainer";
import { MediaExtType, MediaStreamType } from "@app/components/media/mediaplayer";
import { hidenHeaderFooter, showHeaderFooter } from "@app/components/hidenheaderfooter";
import HeartBeat from "@app/components/heartbeat";
import * as moment from 'moment';
var ProgressTimeSpan = 15000;
var LessonProgress;
(function (LessonProgress) {
    LessonProgress[LessonProgress["begin"] = 0] = "begin";
    LessonProgress[LessonProgress["end"] = 1] = "end";
    LessonProgress[LessonProgress["middle"] = 2] = "middle";
})(LessonProgress || (LessonProgress = {}));
var LessonPlayerPage = /** @class */ (function (_super) {
    __extends(LessonPlayerPage, _super);
    function LessonPlayerPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.lessonProgress = LessonProgress.middle;
        _this.progressCtl = null;
        _this.progressPos = {
            time: 0,
            timer: null
        };
        _this.onCurrentStarted = function (context) {
            unmaskThrottle();
        };
        _this.onEnded = function (times) {
            _this.clearProgressTimer();
            var navigateTo = function () {
                var _a = _this.props, params = _a.match.params, history = _a.history;
                navigateBy(params, history);
            };
            _this.uploadLogs().then(function () {
                _this.progressCtl.setItem([0, times.duration]);
                navigateTo();
            }).catch(function (e) {
                navigateTo();
            });
        };
        _this.onStoped = function (times) {
            var _a = _this.props, params = _a.match.params, history = _a.history;
            if (!times) {
                navigateBy(params, history);
            }
            _this.setProgressData(times.time);
            navigateBy(params, history);
        };
        _this.onTimeUpdate = function (times) {
            if (!times) {
                return;
            }
            _this.progressPos.time = times.time;
        };
        _this.onPlay = function () {
            _this.progressPos.timer = setInterval(function () {
                _this.setProgressData(_this.progressPos.time);
            }, ProgressTimeSpan);
        };
        _this.onPause = function () {
            _this.clearProgressTimer();
        };
        _this.onDropdownVisibleChange = function (visible) {
            _this.setState({ visibleBar: visible });
        };
        _this.onPlaybackRateChange = function (value) {
            _this.setState({ playbackRate: value });
        };
        _this.state = {
            currentTime: 0,
            progressMax: 0,
            resources: {},
            signature: {
                pageContainer: '',
                audioContainer: '',
                videoContainer: '',
            },
            playbackRate: 1,
            visibleBar: false,
        };
        _this.exitHandler = _this.exitHandler.bind(_this);
        return _this;
    }
    LessonPlayerPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, userId, versionId, unitParam, lessonId, mediaId, unit, _b, isKeeper, loginUser, _c, lessons, media, relatedUser, mediaProgress;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this.props.match.params, userId = _a.userId, versionId = _a.versionId, unitParam = _a.unit, lessonId = _a.lessonId, mediaId = _a.mediaId;
                        unit = limitMaxUnit(unitParam);
                        _b = isKeepUser(userId), isKeeper = _b.isKeeper, loginUser = _b.loginUser;
                        maskThrottle();
                        this.progressCtl = new TSIMediaProgressStorage(userId, mediaId);
                        return [4 /*yield*/, promiseAll([
                                function () { return _this.tsiService.getLessons({ versionId: versionId, unit: unit }); },
                                function () { return _this.tsiService.getMedia({ versionId: versionId, mediaId: mediaId }); },
                                function () { return isKeeper ? Promise.resolve({ children: [] }) : _this.tsiService.getUserInfos({ parentId: loginUser }, { includeAvatar: false }); },
                            ])];
                    case 1:
                        _c = __read.apply(void 0, [_d.sent(), 3]), lessons = _c[0], media = _c[1], relatedUser = _c[2];
                        !isKeeper && this.guardAllowLesson(relatedUser, lessons);
                        mediaProgress = this.progressCtl.getProgress();
                        this.progressPos.time = mediaProgress;
                        this.setState({
                            resources: this.getMediaResource(media),
                            currentTime: mediaProgress,
                            progressMax: this.progressCtl.getProgressMax(),
                        });
                        maskThrottle();
                        hidenHeaderFooter();
                        addExitFullscreenMonitor(this.exitHandler);
                        return [2 /*return*/];
                }
            });
        });
    };
    LessonPlayerPage.prototype.guardAllowLesson = function (relatedUser, lessons) {
        var _a = this.props, _b = _a.match.params, userId = _b.userId, versionId = _b.versionId, unitParam = _b.unit, lessonId = _b.lessonId, history = _a.history;
        var unit = limitMaxUnit(unitParam);
        lessons = lessons.sort(function (left, right) { return left.sequence - right.sequence; }).map(function (lesson, index) { return (lesson.index = index, lesson); });
        if (relatedUser && relatedUser.children && relatedUser.children.length) {
            var child = relatedUser.children.find(function (child) { return child.id === userId; });
            if (!this.needMediaPlayLimit(child, unit)) {
                return;
            }
            child.allowLessonIndex = child.unit > maxTSIUnitCount ? null : child.allowLessonIndex;
            var allowLessonIndex = (child.allowLessonIndex || getMaxTSILessons(unit)) - 1;
            var notAllowLesson = (lessons.find(function (lesson) { return lesson.id == lessonId; }) || { index: Number.MAX_VALUE }).index > allowLessonIndex;
            if (notAllowLesson) {
                history.replace({ pathname: GLUtil.pathStringify(PathConfig.Lessons, { userId: userId, unit: unit, versionId: versionId }) });
            }
        }
    };
    LessonPlayerPage.prototype.needMediaPlayLimit = function (accessUnit, unit) {
        if (unit > 0) {
            if (accessUnit.unit) {
                var limit = function () { return Number(accessUnit.unit) <= unit; };
                return limit() && limitMaxUnit(accessUnit.unit) === unit;
            }
            else {
                return true;
            }
        }
        else {
            return accessUnit.lsUnit ? accessUnit.lsUnit === unit : true;
        }
    };
    LessonPlayerPage.prototype.getMediaResource = function (media) {
        return {
            playlists: [{
                    id: media.id, contents: [{ id: media.id, sequence: 0 }]
                }],
            contents: [{
                    id: media.id,
                    contentGroup: ContentGroupEnum.Movie,
                    medias: [{
                            id: media.id,
                            url: media.streamingUrl,
                            mediaType: MediaExtType.MP4,
                            mediaStreamType: MediaStreamType.smooth
                        }],
                    pages: []
                }]
        };
    };
    LessonPlayerPage.prototype.componentWillUnmount = function () {
        this.clearProgressTimer();
        unmaskThrottle();
        showHeaderFooter();
        removeExitFullscreenMonitor(this.exitHandler);
    };
    LessonPlayerPage.prototype.exitHandler = function () {
        if (!document.fullscreenElement &&
            !document.webkitIsFullScreen &&
            !document.mozFullScreen &&
            !document.msFullscreenElement) {
            this.setState({ fullScreen: false });
        }
    };
    LessonPlayerPage.prototype.uploadLogs = function () {
        var _a = this.props.match.params, lessonId = _a.lessonId, userId = _a.userId, mediaId = _a.mediaId, unit = _a.unit;
        var unitParam = limitMaxUnit(unit);
        var log = {
            tsiLessonId: lessonId,
            studentId: userId,
            tsiMediaId: mediaId,
            lessonId: lessonId,
            mediaId: mediaId,
            unit: unitParam,
            playedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            type: this.lessonProgress,
        };
        return this.tsiLogService.uploadMediaLog(log);
    };
    LessonPlayerPage.prototype.clearProgressTimer = function () {
        this.progressPos.timer && clearInterval(this.progressPos.timer);
    };
    LessonPlayerPage.prototype.setProgressData = function (time) {
        if (this.progressCtl.getProgressMax() < time) {
            this.progressCtl.setProgressMax(time);
            this.setState({ progressMax: time });
        }
        this.progressCtl.setProgress(time);
    };
    LessonPlayerPage.prototype.render = function () {
        var _this = this;
        var _a = this.props.match.params, userId = _a.userId, mediaId = _a.mediaId;
        var _b = this.state, currentTime = _b.currentTime, progressMax = _b.progressMax;
        return React.createElement("div", { className: "lessonplayer media-page" },
            React.createElement(HeartBeat, { userId: userId },
                React.createElement(ContentPlayer, { stream: true, onPlay: this.onPlay, onPause: this.onPause, onStop: this.onStoped, onCurrentStarted: this.onCurrentStarted, onEnded: this.onEnded, onTimeUpdate: this.onTimeUpdate, resources: this.state.resources, signature: this.state.signature, playlistId: mediaId, playbackRate: this.state.playbackRate, visibleBar: this.state.visibleBar, currentTime: currentTime, fullScreen: this.state.fullScreen },
                    React.createElement(CountDownControlContainer, { controlOnScreen: true, playbackRate: this.state.playbackRate, hasCaption: false, captionShow: false, progress: progressMax, onDropdownVisibleChange: this.onDropdownVisibleChange, onPlaybackRateChange: this.onPlaybackRateChange, onFullScreen: function () { return _this.setState({ fullScreen: true }); }, onExitFullScreen: function () { return _this.setState({ fullScreen: false }); } }))));
    };
    __decorate([
        lazyInject(TYPES.ITSIService)
    ], LessonPlayerPage.prototype, "tsiService", void 0);
    __decorate([
        lazyInject(TYPES.ITSILogService)
    ], LessonPlayerPage.prototype, "tsiLogService", void 0);
    LessonPlayerPage = __decorate([
        withRouter,
        connect(function (_a) {
            var _b = _a.theme, leftChanged = _b.leftChanged, leftData = _b.leftData;
            return ({
                leftChanged: leftChanged,
                leftData: leftData
            });
        })
    ], LessonPlayerPage);
    return LessonPlayerPage;
}(Component));
export default LessonPlayerPage;
function navigateBy(params, history) {
    var userId = params.userId, versionId = params.versionId, unit = params.unit;
    history.push({ pathname: GLUtil.pathStringify(PathConfig.Lessons, { userId: userId, unit: unit, versionId: versionId }) });
}
function addExitFullscreenMonitor(handler) {
    document.addEventListener('fullscreenchange', handler);
    document.addEventListener('webkitfullscreenchange', handler);
    document.addEventListener('mozfullscreenchange', handler);
    document.addEventListener('MSFullscreenChange', handler);
}
function removeExitFullscreenMonitor(handler) {
    document.removeEventListener('fullscreenchange', handler);
    document.removeEventListener('webkitfullscreenchange', handler);
    document.removeEventListener('mozfullscreenchange', handler);
    document.removeEventListener('MSFullscreenChange', handler);
}
var TSIMediaProgressStorage = /** @class */ (function () {
    function TSIMediaProgressStorage(userId, mediaId) {
        this.userId = userId;
        this.mediaId = mediaId;
        this.progressStorage = {};
    }
    Object.defineProperty(TSIMediaProgressStorage.prototype, "key", {
        get: function () {
            return "gltsimp_" + this.userId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TSIMediaProgressStorage.prototype, "subKey", {
        get: function () {
            return this.mediaId;
        },
        enumerable: true,
        configurable: true
    });
    TSIMediaProgressStorage.prototype.parseNumber = function (value) {
        var progress = Number(value);
        return Number.isNaN(progress) ? 0 : progress;
    };
    TSIMediaProgressStorage.prototype.parseProgress = function (value) {
        var _this = this;
        return (value || [0, 0]).map(function (v) { return _this.parseNumber(v); });
    };
    TSIMediaProgressStorage.prototype.setProgress = function (value) {
        var progress = this.getItem();
        progress[0] = value;
        this.setItem(progress);
    };
    TSIMediaProgressStorage.prototype.setProgressMax = function (value) {
        var progress = this.getItem();
        progress[1] = value;
        this.setItem(progress);
    };
    TSIMediaProgressStorage.prototype.setItem = function (value) {
        var _this = this;
        try {
            value = this.parseProgress(value);
            var setMemStorage = function () {
                var medias = _this.progressStorage[_this.key] || {};
                medias[_this.subKey] = value;
                _this.progressStorage[_this.key] = medias;
            };
            setMemStorage();
            var setStorage = function (storage) {
                var progresses = JSON.parse(storage.getItem(_this.key) || "{}");
                progresses[_this.subKey] = value;
                storage.setItem(_this.key, JSON.stringify(progresses));
            };
            setStorage(sessionStorage);
            setStorage(localStorage);
        }
        catch (error) {
            console.warn(error);
        }
    };
    TSIMediaProgressStorage.prototype.isEmpty = function (value) {
        return value === null || value === undefined || value.length === 0;
    };
    TSIMediaProgressStorage.prototype.getProgress = function () {
        return this.getItem()[0];
    };
    TSIMediaProgressStorage.prototype.getProgressMax = function () {
        return this.getItem()[1];
    };
    TSIMediaProgressStorage.prototype.getItem = function () {
        var _this = this;
        var value = null;
        var getMemStorage = function () {
            var medias = _this.progressStorage[_this.key];
            if (!!medias) {
                var progress = medias[_this.subKey];
                if (progress && progress.length > 0) {
                    return progress;
                }
            }
            return null;
        };
        value = getMemStorage();
        if (!this.isEmpty(value)) {
            return this.parseProgress(value);
        }
        var getStorage = function (storage) {
            try {
                var progresses = storage.getItem(_this.key);
                if (!!progresses) {
                    var progress = JSON.parse(progresses)[_this.subKey];
                    if (progress && progress.length > 0) {
                        return progress;
                    }
                }
            }
            catch (error) {
                console.warn(error);
            }
            return null;
        };
        if (this.isEmpty(value)) {
            value = getStorage(sessionStorage);
        }
        if (this.isEmpty(value)) {
            value = getStorage(localStorage);
        }
        if (!this.isEmpty(value)) {
            value = this.parseProgress(value);
            this.setItem(value);
            return value;
        }
        return this.parseProgress(value);
    };
    return TSIMediaProgressStorage;
}());
