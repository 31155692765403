var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import SettingDropdownIcon from "./icon/setting-dropdown-icon";
import { Menu } from 'antd-min';
import "./style/speed-controller.less";
import SpeedControlSwitch from "./speed-control-switch";
import SpeedIcon from "./icon/speed-icon";
import CaptionIcon from "./icon/caption-icon";
import CaptionControlSwitch from "./caption-control-switch";
var PlaySetting = /** @class */ (function (_super) {
    __extends(PlaySetting, _super);
    function PlaySetting() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._menuVisible = false;
        _this.onClick = function (event) {
            event.stopPropagation();
            _this.setVisibleByTag(!_this._menuVisible);
        };
        _this.onSlowModeChange = function (checked) {
            _this.props.onPlaybackRateChange && _this.props.onPlaybackRateChange(checked ? 0.7 : 1);
        };
        _this.onCaptionShowChange = function (visible) {
            _this.props.onCaptionShowChange && _this.props.onCaptionShowChange(visible);
        };
        _this.setVisibleByTag = function (tag) {
            _this._menuVisible = tag;
            _this.props.onDropdownVisibleChange && _this.props.onDropdownVisibleChange(tag);
            var base = document.querySelector(".dropdown-base");
            base.style.setProperty("display", tag ? "flex" : "none");
            document.addEventListener("click", _this.documentClickHandler);
        };
        _this.documentClickHandler = function (event) {
            if (!_this.isChildNodeOfDropdown(event.target)) {
                _this.setVisibleByTag(false);
                document.removeEventListener("click", _this.documentClickHandler);
            }
        };
        _this.isChildNodeOfDropdown = function (node) {
            var parent = node;
            while (parent) {
                if (parent.className && parent.className.indexOf && parent.className.indexOf("dropdown-base") >= 0) {
                    return true;
                }
                parent = parent.parentElement;
            }
            return false;
        };
        return _this;
    }
    PlaySetting.prototype.componentWillUnmount = function () {
        document.removeEventListener("click", this.documentClickHandler);
    };
    PlaySetting.prototype.render = function () {
        return (React.createElement("div", { className: "dropdown-wrapper" },
            React.createElement("div", { className: "dropdown-base" },
                React.createElement(Menu, null,
                    this.props.hasCaption && React.createElement("div", { className: "dropdown-container" },
                        React.createElement(CaptionIcon, null),
                        React.createElement("span", { className: "dropdown-item-title" },
                            React.createElement("strong", null, "Subtitle:")),
                        React.createElement(CaptionControlSwitch, { enable: this.props.captionShow, onEnableChanged: this.onCaptionShowChange })),
                    React.createElement("div", { className: "dropdown-container" },
                        React.createElement(SpeedIcon, null),
                        React.createElement("span", { className: "dropdown-item-title" },
                            React.createElement("strong", null, "Speed:")),
                        React.createElement(SpeedControlSwitch, { enable: this.props.playbackRate !== 1, onEnableChanged: this.onSlowModeChange })))),
            React.createElement("div", { className: "flat-button", onClick: this.onClick },
                React.createElement(SettingDropdownIcon, null))));
    };
    return PlaySetting;
}(Component));
export default PlaySetting;
