import theme from "./theme";
import signature from "./signature";
import autoplayAlert from "./autoplayalert";
import playlistplayer from "./playlistplayer";
import drawer from "./drawer";
import studentCodeBase from "./studentcodebase";
import retirement from "./retirement";
export default [
    theme,
    signature,
    autoplayAlert,
    playlistplayer,
    drawer,
    studentCodeBase,
    retirement,
];
