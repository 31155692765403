var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var retirementBannerClosedKey = "retirement-banner-closed";
export default {
    namespace: "retirement",
    state: {
        isClosed: localStorage.getItem(retirementBannerClosedKey) === "true"
    },
    reducers: {
        setIsClosed: function (state, action) {
            if (action.payload) {
                localStorage.setItem(retirementBannerClosedKey, "true");
            }
            else {
                localStorage.removeItem(retirementBannerClosedKey);
            }
            return __assign({}, state, { isClosed: action.payload });
        }
    }
};
export function setIsClosed(isClosed) {
    return { type: "retirement/setIsClosed", payload: isClosed };
}
