var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import { Progress } from "antd-min";
import { getTime, PlayAction } from "./mediaplayer";
import PlayerButton from "./player-button";
import { getPausePlayer, getStopPlayer, IEVersion } from "@app/util";
import FullScreenButton from "@app/components/fullscreen/full-screen-button";
import PlaySetting from "@app/components/speedcontroller/speed-control-dropdown";
import { Fastwards } from "../fastwards/fastwards";
var CountDownControlContainer = /** @class */ (function (_super) {
    __extends(CountDownControlContainer, _super);
    function CountDownControlContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.onDropdownVisibleChange = function (visible) {
            _this.props.onDropdownVisibleChange && _this.props.onDropdownVisibleChange(visible);
        };
        _this.onPlaybackRateChange = function (value) {
            _this.props.onPlaybackRateChange && _this.props.onPlaybackRateChange(value);
        };
        _this.onCaptionShowChange = function (visible) {
            _this.props.onCaptionShowChange && _this.props.onCaptionShowChange(visible);
        };
        _this.state = {};
        return _this;
    }
    CountDownControlContainer.prototype.renderControlButton = function () {
        var _a = this.props, paused = _a.paused, isPaused = _a.isPaused, play = _a.play, pause = _a.pause, stop = _a.stop;
        return (React.createElement(React.Fragment, null,
            React.createElement(PlayerButton, { iconType: isPaused ? PlayAction.play : PlayAction.pause, onClick: function () { return paused() ? play() : (pause(), setAudio(getPausePlayer())); } }),
            React.createElement(PlayerButton, { iconType: PlayAction.stop, onClick: function () { return stopAudio(pause, stop); } })));
    };
    CountDownControlContainer.prototype.render = function () {
        var _a = this.props, _b = _a.times, time = _b.time, duration = _b.duration, controlOnScreen = _a.controlOnScreen, progress = _a.progress, seek = _a.seek, onFullScreen = _a.onFullScreen, onExitFullScreen = _a.onExitFullScreen;
        return React.createElement("div", { className: "countdown-controlbar media-screen-dimensions" },
            controlOnScreen && React.createElement("div", { className: "screen-control " + (IEVersion() != -1 ? "IE_EDGE_FIX_STYLE" : "") }, this.renderControlButton()),
            React.createElement("div", { className: "controlbar mediaplayer-controlbar" },
                React.createElement(Fastwards, { progress: progress, time: time, seek: seek }),
                React.createElement(Progress, { percent: percent(time, duration), format: function () { return getTime((duration - time)); } }),
                !controlOnScreen && this.renderControlButton(),
                React.createElement(PlaySetting, { playbackRate: this.props.playbackRate, hasCaption: this.props.hasCaption, captionShow: this.props.captionShow, onDropdownVisibleChange: this.onDropdownVisibleChange, onCaptionShowChange: this.onCaptionShowChange, onPlaybackRateChange: this.onPlaybackRateChange }),
                React.createElement(FullScreenButton, { onFullScreen: onFullScreen, onExitFullScreen: onExitFullScreen })),
            React.createElement("audio", { id: 'playlist-audio', style: { display: 'none' }, src: getStopPlayer() }));
    };
    return CountDownControlContainer;
}(Component));
export default CountDownControlContainer;
function percent(progress, total) {
    if (progress === void 0) { progress = 0; }
    if (total === void 0) { total = 1; }
    progress = progress / 1000;
    total = total / 1000;
    return Math.floor((progress / total) * 100);
}
function setAudio(src) {
    var audio = document.getElementById('playlist-audio');
    audio.src = src;
    audio.play();
}
function stopAudio(pause, stop) {
    pause();
    setAudio(getStopPlayer());
    setTimeout(stop, 500);
}
