import React from "react";
export var BadgeType;
(function (BadgeType) {
    BadgeType["Small"] = "badge-small";
    BadgeType["Large"] = "badge-large";
})(BadgeType || (BadgeType = {}));
export default function Badge(_a) {
    var percentage = _a.percentage, type = _a.type;
    var index = Math.ceil(percentage * 10);
    return (React.createElement("div", { className: "badge " + type },
        React.createElement("div", { className: "base-layer" },
            React.createElement("div", { className: "percentage-layer percentage-" + index }))));
}
