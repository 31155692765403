var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var _this = this;
import * as React from "react";
import { Switch } from "react-router-dom";
import { PathConfig } from "./pathconfig";
import { GLDefaultRoutes, AuthRouteHandler, RoleRouteHandler, RoleName, GLRoute, CallbackRouteHandler, OidcProxy, unmaskThrottle, GLGlobal, PathConfig as GLPathConfig, GLUtil, maskThrottle } from "gl-commonui";
import PageContainer from "@app/components/page-container";
import HomePage from "@app/page/index";
import ChildrenPage from "@app/page/children/children";
import UnitsPage from "@app/page/units/units";
import { themeChangedDispatch } from "@app/states/theme";
import { changeThemeColor, isGuid, getParentChildrenCache, userHasRole, limitMaxUnit } from "@app/util/func";
import LessonsPage from "@app/page/lesson/lessons";
import LessonPlayerPage from "@app/page/lesson/lessonplayer";
import { StudentHomePage } from "@app/page/student-home/student-home";
import { StudentLogin } from "@app/page/student-login/student-login";
import { StudentAppService } from "@app/service/app/student";
import { AccessStatus, setAccessStatus } from "@app/states/studentcodebase";
import waitingCheckAccess from "@app/hoc/waitingCheckAccess";
import { LessonVideosAccess } from "./route-handler";
var ChangeThemeHandler = /** @class */ (function () {
    function ChangeThemeHandler(changed) {
        if (changed === void 0) { changed = {}; }
        this.changed = changed;
    }
    ChangeThemeHandler.prototype.invoke = function (context) {
        changeTheme(true, this.changed);
        this.changed.ignoreThemeColorChange
            ? changeThemeColor(undefined)
            : context.match.params.unit &&
                changeThemeColor(context.match.params.unit);
        this.next.invoke(context);
    };
    return ChangeThemeHandler;
}());
var UnchangeThemeHandler = /** @class */ (function () {
    function UnchangeThemeHandler(unChanged) {
        if (unChanged === void 0) { unChanged = {}; }
        this.unChanged = unChanged;
    }
    UnchangeThemeHandler.prototype.invoke = function (context) {
        changeTheme(false, this.unChanged.override);
        this.unChanged.color && changeThemeColor();
        this.next.invoke(context);
    };
    return UnchangeThemeHandler;
}());
function changeTheme(changed, changes) {
    themeChangedDispatch(__assign({ leftChanged: changed, midChanged: changed, rightChanged: changed }, changes));
}
var permissonGuardHandler = new CallbackRouteHandler(function (context) {
    var userId = context.match.params.userId;
    var parentChildren = getParentChildrenCache();
    if (parentChildren.length > 0 &&
        isGuid(userId) &&
        !parentChildren.some(function (e) { return e === userId; })) {
        window.location.replace(PathConfig.Home);
    }
}, function () { });
var redirectToAccessDenied = function () { return window.location.replace(GLPathConfig.AccessDenied); };
var tsiGuardHandler = new CallbackRouteHandler(function (context) { return __awaiter(_this, void 0, void 0, function () {
    var loginInfo, unit, accessStatus, _a, service, _b, studentInfo, unit_1, lsUnit, maxUnit, targetUnit, error_1;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                loginInfo = GLGlobal.loginInfo();
                if (!loginInfo.loggedin) return [3 /*break*/, 7];
                unit = GLUtil.pathParse(PathConfig.Unit).unit;
                if (!isNaN(parseInt(unit)) && parseInt(unit) < 0) {
                    window.location.replace(PathConfig.Home);
                }
                accessStatus = GLGlobal.store.getState().studentcodebase.accessStatus;
                _a = accessStatus;
                switch (_a) {
                    case AccessStatus.DEFAULT: return [3 /*break*/, 1];
                }
                return [3 /*break*/, 6];
            case 1:
                maskThrottle();
                service = new StudentAppService();
                _c.label = 2;
            case 2:
                _c.trys.push([2, 4, , 5]);
                return [4 /*yield*/, Promise.all([
                        service.getStudentInfo({
                            id: loginInfo.profile.sub
                        }),
                        service.getTsiAccess()
                    ])];
            case 3:
                _b = __read.apply(void 0, [_c.sent(), 1]), studentInfo = _b[0];
                if (studentInfo) {
                    unit_1 = studentInfo.unit, lsUnit = studentInfo.lsUnit;
                    maxUnit = unit_1 ? unit_1 : lsUnit;
                    targetUnit = limitMaxUnit(maxUnit);
                    if (targetUnit < 0) {
                        redirectToAccessDenied();
                    }
                    else {
                        GLGlobal.store.dispatch(setAccessStatus(AccessStatus.UNLOCK));
                    }
                }
                unmaskThrottle();
                return [3 /*break*/, 5];
            case 4:
                error_1 = _c.sent();
                redirectToAccessDenied();
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 7];
            case 6: return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}); }, function () { });
var handlers = [
    new AuthRouteHandler(),
    new RoleRouteHandler([
        RoleName.systemAdmin,
        RoleName.globalHead,
        RoleName.trainingAdmin,
        RoleName.regionAdmin,
        RoleName.accountManager,
        RoleName.trainer,
        RoleName.schoolAdmin,
        RoleName.campusAdmin,
        RoleName.teacher,
        RoleName.parent,
        RoleName.student,
        RoleName.trainingManager
    ])
];
var StudentBasicHandlers = [
    new AuthRouteHandler(),
    new RoleRouteHandler([RoleName.student])
];
var studentHandlers = __spread(StudentBasicHandlers, [
    new CallbackRouteHandler(function () { }, function () {
        unmaskThrottle();
    }),
    tsiGuardHandler
]);
var lessonVideosHandlers = [
    new AuthRouteHandler(),
    new RoleRouteHandler([
        RoleName.systemAdmin,
        RoleName.globalHead,
        RoleName.trainingAdmin,
        RoleName.regionAdmin,
        RoleName.accountManager,
        RoleName.trainer,
        RoleName.schoolAdmin,
        RoleName.campusAdmin,
        RoleName.teacher,
        RoleName.parent,
        RoleName.student,
        RoleName.trainingManager
    ]), new LessonVideosAccess()
];
var parentRoute = (React.createElement(PageContainer, null,
    React.createElement(Switch, null,
        React.createElement(GLRoute, { exact: true, path: PathConfig.Home, component: HomePage, handlers: handlers.concat([
                new UnchangeThemeHandler({ color: true })
            ]) }),
        React.createElement(GLRoute, { exact: true, path: PathConfig.Children, component: ChildrenPage, handlers: handlers.concat([new UnchangeThemeHandler()]) }),
        React.createElement(GLRoute, { exact: true, path: PathConfig.CodeLogin, component: StudentLogin, handlers: [] }),
        React.createElement(GLRoute, { exact: true, path: PathConfig.Lessons, component: LessonsPage, handlers: lessonVideosHandlers.concat([
                new ChangeThemeHandler(),
                permissonGuardHandler
            ]) }),
        React.createElement(GLRoute, { exact: true, path: PathConfig.LessonPlayer, component: LessonPlayerPage, handlers: lessonVideosHandlers.concat([
                new ChangeThemeHandler(),
                permissonGuardHandler
            ]) }),
        React.createElement(GLRoute, { exact: true, path: PathConfig.Units, component: UnitsPage, handlers: lessonVideosHandlers.concat([
                new UnchangeThemeHandler({
                    override: { leftChanged: true }
                }),
                permissonGuardHandler
            ]) }),
        GLDefaultRoutes)));
var studentRoute = (React.createElement(PageContainer, null,
    React.createElement(Switch, null,
        React.createElement(GLRoute, { exact: true, path: PathConfig.Home, component: StudentHomePage, handlers: studentHandlers }),
        React.createElement(GLRoute, { exact: true, path: PathConfig.CodeLogin, component: StudentLogin, handlers: [] }),
        React.createElement(GLRoute, { exact: true, path: PathConfig.Lessons, component: waitingCheckAccess(LessonsPage), handlers: lessonVideosHandlers.concat([
                new ChangeThemeHandler(),
                tsiGuardHandler
            ]) }),
        React.createElement(GLRoute, { exact: true, path: PathConfig.LessonPlayer, component: waitingCheckAccess(LessonPlayerPage), handlers: lessonVideosHandlers.concat([
                new ChangeThemeHandler(),
                tsiGuardHandler
            ]) }),
        React.createElement(GLRoute, { exact: true, path: PathConfig.Units, component: waitingCheckAccess(UnitsPage), handlers: lessonVideosHandlers.concat([
                new UnchangeThemeHandler({
                    override: { leftChanged: true }
                }),
                tsiGuardHandler
            ]) }),
        GLDefaultRoutes)));
var routerPromise = OidcProxy.getUser().then(function (loginInfo) {
    var isStudent = loginInfo.loggedin && userHasRole(RoleName.student, loginInfo.profile);
    var result = {
        isStudent: isStudent,
        router: isStudent ? studentRoute : parentRoute
    };
    return result;
});
export default routerPromise;
