var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { render } from "react-dom";
import routerPromise from "./config/router";
import { reducers } from "./reducers/index";
import { AppBase, polyfillSupport, GLGlobal, OidcProxy } from 'gl-commonui';
import { defineMasterPage, swRegister } from './util/index';
import { intlLoaded } from './locales/index';
import "./util/binds";
import "./style/index.less";
import "./style/index.ja.less";
import states from './states';
import HeartBeat from './util/heartbeat';
import { AnnouncementBanner } from "@app/components/announcement-banner/announcement-banner";
Promise.all([polyfillSupport(), routerPromise]).then(function (response) {
    var _a = __read(response, 2), routerResponse = _a[1];
    var router = routerResponse.router, isStudent = routerResponse.isStudent;
    render(React.createElement(AppBase, { masterPagesDefined: defineMasterPage(isStudent), states: states, reducers: reducers, intlLoaded: intlLoaded },
        React.createElement(AnnouncementBanner, null),
        router), document.getElementById("app_content"));
    window.onbeforeunload = function () {
        var loginInfo = GLGlobal.loginInfo();
        if (loginInfo && OidcProxy.isExpired(loginInfo) === false && heartBeat) {
            heartBeat.dispose();
        }
    };
});
var heartBeat = new HeartBeat();
export function set_heartbeat_enable(enable) {
    heartBeat.set_enable(enable);
}
export function heartbeat_register_state_change(userId, onSessionStateChanged) {
    heartBeat.register(userId, onSessionStateChanged);
}
export function heartbeat_unregister_state_change() {
    heartBeat.unregister();
}
swRegister();
