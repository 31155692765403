export var StudentAction;
(function (StudentAction) {
    StudentAction["None"] = "GA_HomePage_None";
})(StudentAction || (StudentAction = {}));
export var ContentGroupEnum;
(function (ContentGroupEnum) {
    ContentGroupEnum["Song"] = "Song";
    ContentGroupEnum["Movie"] = "Movie";
    ContentGroupEnum["Story"] = "Story";
    ContentGroupEnum["VPC"] = "VPC";
    ContentGroupEnum["Question"] = "Question";
})(ContentGroupEnum || (ContentGroupEnum = {}));
export var ServerContentGroupEnum;
(function (ServerContentGroupEnum) {
    ServerContentGroupEnum[ServerContentGroupEnum["None"] = -1] = "None";
    ServerContentGroupEnum[ServerContentGroupEnum["Song"] = 0] = "Song";
    ServerContentGroupEnum[ServerContentGroupEnum["Movie"] = 1] = "Movie";
    ServerContentGroupEnum[ServerContentGroupEnum["Story"] = 2] = "Story";
    ServerContentGroupEnum[ServerContentGroupEnum["Reader"] = 3] = "Reader";
    ServerContentGroupEnum[ServerContentGroupEnum["Writer"] = 4] = "Writer";
    ServerContentGroupEnum[ServerContentGroupEnum["VPC"] = 5] = "VPC";
})(ServerContentGroupEnum || (ServerContentGroupEnum = {}));
export var PlayerRole;
(function (PlayerRole) {
    PlayerRole[PlayerRole["Text"] = 0] = "Text";
    PlayerRole[PlayerRole["Digital"] = 1] = "Digital";
    PlayerRole[PlayerRole["Dual"] = 2] = "Dual";
    PlayerRole[PlayerRole["Other"] = 128] = "Other";
})(PlayerRole || (PlayerRole = {}));
export var SubscriptionTypeUsage;
(function (SubscriptionTypeUsage) {
    SubscriptionTypeUsage[SubscriptionTypeUsage["Standard"] = 0] = "Standard";
    SubscriptionTypeUsage[SubscriptionTypeUsage["Dual"] = 1] = "Dual";
    SubscriptionTypeUsage[SubscriptionTypeUsage["Single"] = 2] = "Single";
})(SubscriptionTypeUsage || (SubscriptionTypeUsage = {}));
export var LicenseType;
(function (LicenseType) {
    LicenseType[LicenseType["GrapeSEED"] = 0] = "GrapeSEED";
    LicenseType[LicenseType["LittleSEED"] = 1] = "LittleSEED";
})(LicenseType || (LicenseType = {}));
