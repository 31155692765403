var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from "react";
import { connect, GLUtil, GLGlobal } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import { TeachingLocale } from "@app/locales/localeid";
import { setIsDrawerVisible } from "@app/states/drawer";
var RightHeader = /** @class */ (function (_super) {
    __extends(RightHeader, _super);
    function RightHeader(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            menuVisible: false
        };
        _this.handleClickCloseDrawer = _this.handleClickCloseDrawer.bind(_this);
        return _this;
    }
    RightHeader.prototype.handleClickCloseDrawer = function () {
        this.props.setIsDrawerVisible(false);
    };
    RightHeader.prototype.render = function () {
        var _a = this.props, rightChanged = _a.rightChanged, children = _a.children;
        var menuVisible = this.state.menuVisible;
        return React.createElement("div", { className: "header-right" },
            React.createElement("div", { className: "imgs" },
                React.createElement("a", { target: "_blank", href: GLGlobal.portals().student, onClick: this.handleClickCloseDrawer },
                    React.createElement("h1", { className: 'rep-online-icon', title: GLGlobal.intl.formatMessage({ id: TeachingLocale.REPOnlineText }) }))),
            React.createElement("div", { className: "divider" }),
            children);
    };
    RightHeader = __decorate([
        connect(function (_a) {
            var rightChanged = _a.theme.rightChanged;
            return ({
                rightChanged: rightChanged,
            });
        }, {
            setIsDrawerVisible: setIsDrawerVisible
        })
    ], RightHeader);
    return RightHeader;
}(Component));
export default RightHeader;
function getPath(pathname) {
    var _a = GLUtil.pathParse(PathConfig.Unit), userId = _a.userId, versionId = _a.versionId, unit = _a.unit;
    return { pathname: GLUtil.pathStringify(pathname, { userId: userId, unit: unit, versionId: versionId }) };
}
