var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as request from 'superagent';
import GLIndexDBProxy from "./indexed-db";
import { isChromeFirefox, isSafari } from './func';
export var GRAPESEEDRESOURCESVERSION = 'grapeseed-resources-versions';
var ResourcesCache = /** @class */ (function () {
    function ResourcesCache() {
        this.cache = ResourcesCache.indexedDB || (ResourcesCache.indexedDB = new GLIndexDBProxy('grapeseed-resources', 1));
        this.versions = JSON.parse(localStorage.getItem(GRAPESEEDRESOURCESVERSION) || "[]");
    }
    ResourcesCache.prototype.isSupport = function () {
        return GLIndexDBProxy.isSupport();
    };
    ResourcesCache.prototype.init = function () {
        return this.cache.open();
    };
    ResourcesCache.prototype.restoreVersions = function () {
        localStorage.setItem(GRAPESEEDRESOURCESVERSION, JSON.stringify(this.versions));
        return Promise.resolve(true);
    };
    ResourcesCache.prototype.has = function (id, updateTime) {
        var _this = this;
        return this.cache.has(id).then(function (has) {
            if (has) {
                var index = _this.versions.findIndex(function (v) { return v.id === id; });
                if (index === -1) {
                    _this.versions.push({ id: id, updateTime: updateTime });
                }
                else {
                    if (_this.versions[index].updateTime < updateTime) {
                        return _this.cache.delete(id).then(function () { return Promise.resolve(!has); });
                    }
                }
            }
            else {
                var index = _this.versions.findIndex(function (v) { return v.id === id; });
                index > -1 && _this.versions.splice(index, 1);
            }
            return Promise.resolve(has);
        });
    };
    ResourcesCache.prototype.addCache = function (id, blob, src, callback) {
        var _this = this;
        if (callback === void 0) { callback = function (data) { return true; }; }
        return formatCachingAsync({ query: id, data: blob, exts: formatExts(src) })
            .then(function (query) {
            _this.cache.add(query);
            return Promise.resolve(callback(query.data));
        });
    };
    ResourcesCache.prototype.tryGet = function (id, src, updateTime) {
        var _this = this;
        return this.has(id, updateTime).then(function (has) {
            if (has) {
                return _this.cache.get(id).then(function (d) { return Promise.resolve(d.data); });
            }
            else {
                return _this.fetch(src).then(function (blob) { return _this.addCache(id, blob, src, function (data) { return data; }); });
            }
        });
    };
    ResourcesCache.prototype.caches = function (resources) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var tryAdd = function (id, src, updateTime) {
                return _this.has(id, updateTime).then(function (has) {
                    if (!has) {
                        return _this.fetch(src).then(function (blob) { return _this.addCache(id, blob, src); });
                    }
                    return Promise.resolve(true);
                });
            };
            var promises = resources.map(function (_a) {
                var id = _a.id, src = _a.src, updateTime = _a.updateTime;
                return tryAdd(id, src, updateTime);
            });
            Promise.all(promises).then(resolve).catch(reject);
        });
    };
    ResourcesCache.prototype.fetch = function (url) {
        return new Promise(function (resolve, reject) {
            request
                .get(url)
                .responseType('blob')
                .end(function (error, response) {
                if (error) {
                    reject(error);
                }
                else if (response.ok) {
                    resolve(response.body);
                }
            });
        });
    };
    ResourcesCache.prototype.getCache = function (_a) {
        var id = _a.id, src = _a.src, updateTime = _a.updateTime;
        return this.tryGet(id, src, updateTime);
    };
    ResourcesCache.prototype.getCachedUrl = function (params) {
        return this.getCache(params).then(function (d) { return Promise.resolve(formatObjectURL(d, params.id)); });
    };
    ResourcesCache.prototype.getUrl = function (_a) {
        var _this = this;
        var id = _a.id, src = _a.src, updateTime = _a.updateTime;
        return this.has(id, updateTime).then(function (has) {
            if (has) {
                return _this.cache.get(id).then(function (d) { return Promise.resolve(formatObjectURL(d.data, id)); });
            }
            else {
                return Promise.resolve(src);
            }
        });
    };
    return ResourcesCache;
}());
export default ResourcesCache;
function createObjectURL(object) {
    return isSafari() ? webkitURL.createObjectURL(object) : URL.createObjectURL(object);
}
function formatObjectURL(data, id) {
    return isChromeFirefox() ? createObjectURL(data) + "#?" + encodeURIComponent(id) : createObjectURL(data);
}
function formatExts(src) {
    return {
        name: src.split('?').shift().split('/').pop()
    };
}
function formatCaching(cachedData) {
    if (!cachedData.data.type.endsWith('mp3') && cachedData.exts && cachedData.exts.name.endsWith('.mp3')) {
        var blob = cachedData.data;
        return __assign({}, cachedData, { data: blob.slice(0, blob.size, "audio/mpeg") });
    }
    return cachedData;
}
function formatCachingAsync(cachedData) {
    if (isSafari() && !cachedData.data.type.endsWith('mp3') && cachedData.exts && cachedData.exts.name.endsWith('.mp3')) {
        var blob = cachedData.data;
        return formatBlob(blob).then(function (blob) { return (__assign({}, cachedData, { data: blob })); });
    }
    return Promise.resolve(cachedData);
}
function formatBlob(blob) {
    return cpBlob(blob, { type: "audio/mpeg" });
}
function cpBlob(blob, options) {
    return new Promise(function (resolve, reject) {
        var fileReader = new FileReader();
        fileReader.onload = function (e) {
            var arrayBuffer = this.result;
            resolve(new Blob([arrayBuffer], options));
        };
        fileReader.readAsArrayBuffer(blob);
    });
}
