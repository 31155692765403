var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from "react";
import { withRouter, connect, GLUtil, NotificationType, MessageHelper, maskThrottle, unmaskThrottle } from "gl-commonui";
import { PathConfig } from '@app/config/pathconfig';
import { Icon } from "antd-min";
import { lazyInject, TYPES, limitMaxUnit, fmtMsg, maxTSIUnitCount, isKeepUser, getMaxTSILessons, newGuid, promiseAll, getMaxTSILessonMedias } from "@app/util";
import UnitButton from "@app/components/unitbutton";
import { showHeaderFooter } from "@app/components/hidenheaderfooter";
import HeartBeat from "@app/components/heartbeat";
import "./style/card.less";
import { PlayStates } from "@app/service/tsilog";
import { TeachingLocale } from "@app/locales/localeid";
var ComingSoonMediaNumber = 2;
function getLimitLessonIndex(allowLessonIndex, maxTSILessons) {
    return Math.min(allowLessonIndex, maxTSILessons - 1);
}
var LessonsPage = /** @class */ (function (_super) {
    __extends(LessonsPage, _super);
    function LessonsPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.originLessons = [];
        _this.state = {
            lessons: [],
            badgePercentage: 0,
        };
        return _this;
    }
    LessonsPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, userId, versionId, unitParam, unit, _b, isKeeper, loginUser, _c, lessons, badgeProgress, playlogs, accessUnit, relatedUser, currentUnitMaxLesson, allowLessonIndex, lessonTmp, badgePercentage;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this.props.match.params, userId = _a.userId, versionId = _a.versionId, unitParam = _a.unit;
                        unit = limitMaxUnit(unitParam);
                        _b = isKeepUser(userId), isKeeper = _b.isKeeper, loginUser = _b.loginUser;
                        showHeaderFooter();
                        maskThrottle();
                        return [4 /*yield*/, promiseAll([
                                function () { return _this.tsiService.getLessons({ versionId: versionId, unit: unit }); },
                                function () { return _this.tsiLogService.getUnitLessonBadges({ studentId: userId, unit: unit }); },
                                function () { return _this.tsiLogService.getPlayLogs({ studentId: userId, unit: unit }); },
                                function () { return isKeeper ? _this.accessUnitService.getAccessUnit({ parentId: userId }) : _this.tsiService.getStudentAccessUnit({ id: userId }); },
                                function () { return isKeeper ? Promise.resolve({ children: [] }) : _this.tsiService.getUserInfos({ parentId: loginUser }, { includeAvatar: false }); },
                            ])];
                    case 1:
                        _c = __read.apply(void 0, [_d.sent(), 5]), lessons = _c[0], badgeProgress = _c[1], playlogs = _c[2], accessUnit = _c[3], relatedUser = _c[4];
                        currentUnitMaxLesson = getMaxTSILessons(unit);
                        allowLessonIndex = getLimitLessonIndex(this.getAllowLessonIndex(relatedUser, userId, currentUnitMaxLesson), currentUnitMaxLesson);
                        lessonTmp = this.sliceLessons(lessons);
                        lessonTmp = this.formatLessons(lessonTmp, currentUnitMaxLesson);
                        lessonTmp = this.setMediaPlayLimit(lessonTmp, this.needMediaPlayLimit(accessUnit, unit, isKeeper), allowLessonIndex);
                        this.originLessons = this.buildMedias(lessonTmp, playlogs.reduce(function (medias, lesson) { return medias.concat(lesson.medias.map(function (m) { return m.id; })); }, []));
                        badgePercentage = badgeProgress.count / getMaxTSILessonMedias(unit);
                        this.setState({ lessons: __spread(this.originLessons), badgePercentage: badgePercentage });
                        unmaskThrottle();
                        return [2 /*return*/];
                }
            });
        });
    };
    LessonsPage.prototype.sliceLessons = function (lessons) {
        if (!lessons) {
            return lessons;
        }
        for (var index = 0; index < lessons.length; index++) {
            var lesson = lessons[index];
            if (parseInt(lesson.title.split(' ')[1]) != index + 1) {
                return lessons.slice(0, index);
            }
        }
        return lessons;
    };
    LessonsPage.prototype.getAllowLessonIndex = function (relatedUser, childId, maxTSILessons) {
        if (relatedUser && relatedUser.children && relatedUser.children.length) {
            var child = relatedUser.children.find(function (child) { return child.id === childId; });
            child.allowLessonIndex = child.unit > maxTSIUnitCount ? null : child.allowLessonIndex;
            return (child.allowLessonIndex || maxTSILessons) - 1;
        }
        return Number.MAX_VALUE;
    };
    LessonsPage.prototype.formatLessons = function (lessons, maxTSILessons) {
        var formated = lessons.sort(function (left, right) { return left.sequence - right.sequence; }).map(function (lesson, index) {
            lesson.index = index;
            lesson.state = PlayStates.UnLocked;
            lesson.tsiMedias.forEach(function (m) {
                m.state = PlayStates.UnLocked;
            });
            return lesson;
        });
        function concatArr(arr, count, getElem) {
            return arr
                .concat(new Array(count)
                .fill(arr.length)
                .map(function (startIndex, index) {
                index += startIndex;
                return getElem(index);
            }));
        }
        var appendCount = maxTSILessons - lessons.length;
        function appendComingSoonLessons() {
            return concatArr(formated, appendCount, function (index) { return ({
                id: newGuid(),
                index: index,
                title: "Lesson " + (index + 1),
                tsiMedias: []
            }); });
        }
        return appendCount > 0 ? appendComingSoonLessons() : formated;
    };
    LessonsPage.prototype.needMediaPlayLimit = function (accessUnit, unit, isKeeper) {
        if (isKeeper) {
            return false;
        }
        if (unit > 0) {
            if (accessUnit.unit) {
                var limit = function () { return Number(accessUnit.unit) <= unit; };
                return limit() && limitMaxUnit(accessUnit.unit) === unit;
            }
            else {
                return true;
            }
        }
        else {
            return accessUnit.lsUnit ? accessUnit.lsUnit === unit : true;
        }
    };
    LessonsPage.prototype.lockedByAllowLesson = function (lessons, allowLessonIndex) {
        return lessons.filter(function (lesson) { return lesson.index <= allowLessonIndex; });
    };
    LessonsPage.prototype.setFreePlayStates = function (lessons) {
        var setFreePlayState = function (state) { return [PlayStates.Locked, PlayStates.Unlocking].some(function (s) { return s === state; }) ? PlayStates.UnLocked : state; };
        lessons.forEach(function (lesson) {
            lesson.state = setFreePlayState(lesson.state);
            lesson.tsiMedias.forEach(function (media) {
                media.state = setFreePlayState(media.state);
            });
        });
        return lessons;
    };
    LessonsPage.prototype.setMediaPlayLimit = function (lessons, needMediaPlayLimit, allowLessonIndex) {
        if (!needMediaPlayLimit) {
            return this.setFreePlayStates(lessons);
        }
        lessons = this.lockedByAllowLesson(lessons, allowLessonIndex);
        return lessons;
    };
    LessonsPage.prototype.getLessonPlayState = function (state) {
        if (state === PlayStates.Played) {
            return 'card-played';
        }
        else if (state === PlayStates.UnLocked) {
            return 'card-unlocked';
        }
        else if (state === PlayStates.Unlocking) {
            return 'card-unlocking';
        }
        else if (state === PlayStates.Locked) {
            return 'card-locked';
        }
        else if (state === PlayStates.ComingSoon) {
            return 'card-coming-soon';
        }
        throw "Undefined LessonPlayStates";
    };
    LessonsPage.prototype.clickLesson = function (lesson) {
        var _a = this.props, history = _a.history, _b = _a.match.params, userId = _b.userId, unit = _b.unit, versionId = _b.versionId;
        if (lesson.state === PlayStates.Locked || lesson.state === PlayStates.ComingSoon) {
            return;
        }
        if (lesson.isMedia && !lesson.id.endsWith('comingsoon')) {
            history.push({
                pathname: GLUtil.pathStringify(PathConfig.LessonPlayer, {
                    userId: userId, versionId: versionId, unit: unit, lessonId: lesson.lessonId, mediaId: lesson.id
                }),
            });
        }
        else {
            MessageHelper.Message(NotificationType.Failed, fmtMsg(TeachingLocale.MsgWarningNoMedia, { unit: unit, lessontitle: lesson.title }));
        }
    };
    LessonsPage.prototype.buildMedias = function (lessons, mediaLogs) {
        var buildEmptyLessonMedia = function (_a) {
            var id = _a.id, title = _a.title;
            return new Array(ComingSoonMediaNumber).fill({
                lessonId: id,
                state: PlayStates.ComingSoon,
                isMedia: true
            }).map(function (m, index) {
                return __assign({}, m, { id: id + "-" + index + "-comingsoon", title: title + "." + (index + 1) });
            });
        };
        return lessons.reduce(function (lessonMedias, lesson) {
            if (lesson.tsiMedias.length > 0) {
                var mediaLessons = lesson.tsiMedias.sort(function (left, right) { return left.sequence - right.sequence; }).map(function (m, index) {
                    return __assign({}, m, { lessonId: lesson.id, title: lesson.title + "." + (index + 1), isMedia: true });
                });
                mediaLessons[0].isFirstMedia = true;
                mediaLessons[mediaLessons.length - 1].isLastMedia = true;
                return lessonMedias.concat(mediaLessons);
            }
            else {
                return lessonMedias.concat(buildEmptyLessonMedia(lesson));
            }
        }, [])
            .map(function (lesson) {
            if (lesson.state == PlayStates.Locked || lesson.state == PlayStates.Unlocking) {
                lesson.state = PlayStates.UnLocked;
            }
            if (mediaLogs.some(function (m) { return m === lesson.id; })) {
                lesson.state = PlayStates.Played;
            }
            return lesson;
        });
    };
    LessonsPage.prototype.getLessonMediaStateIcon = function (state) {
        switch (state) {
            case PlayStates.UnLocked: return React.createElement(GrayCamera, null);
            case PlayStates.ComingSoon: return React.createElement(ComingSoon, null);
            default: return React.createElement(BlackCamera, null);
        }
    };
    LessonsPage.prototype.render = function () {
        var _this = this;
        var _a = this.state, lessons = _a.lessons, badgePercentage = _a.badgePercentage;
        var _b = this.props, history = _b.history, _c = _b.match.params, userId = _c.userId, unit = _c.unit;
        return React.createElement("div", { className: "lessons" },
            React.createElement(HeartBeat, { userId: userId },
                React.createElement("div", { className: "lessons-toolbar" },
                    React.createElement(UnitButton, { history: history, unit: unit, userId: userId, from: "lessons", isShowBadge: true, percentage: badgePercentage, enableBlink: false, activeBlink: false, activedBlinkCallback: function () { }, callActivedItem: function () { } })),
                React.createElement("div", { className: "cards" }, lessons.map(function (lesson) { return (React.createElement("div", { key: lesson.id, className: "card-base " + (lesson.isMedia ? 'media-card' : '') },
                    React.createElement("div", { className: "card " + _this.getLessonPlayState(lesson.state) + " " + (lesson.isMedia ? 'card-group' : ''), onClick: function () { return _this.clickLesson(lesson); } },
                        React.createElement("div", { className: "card-thumbnail" }, _this.getLessonMediaStateIcon(lesson.state)),
                        React.createElement("div", { className: "card-title" }, lesson.title)))); }))));
    };
    __decorate([
        lazyInject(TYPES.IAccessUnitService)
    ], LessonsPage.prototype, "accessUnitService", void 0);
    __decorate([
        lazyInject(TYPES.ITSIService)
    ], LessonsPage.prototype, "tsiService", void 0);
    __decorate([
        lazyInject(TYPES.ITSILogService)
    ], LessonsPage.prototype, "tsiLogService", void 0);
    LessonsPage = __decorate([
        withRouter,
        connect(function (_a) {
            var _b = _a.theme, leftChanged = _b.leftChanged, leftData = _b.leftData;
            return ({
                leftChanged: leftChanged,
                leftData: leftData
            });
        })
    ], LessonsPage);
    return LessonsPage;
}(Component));
export default LessonsPage;
function GrayCamera() {
    return (React.createElement(Icon, { style: { fontSize: '48px' }, component: function () { return (React.createElement("svg", { width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 1024 1024" },
            React.createElement("path", { d: "M928.533333 259.2a21.226667 21.226667 0 0 0-20.736-0.96L682.666667 370.837333V320a106.794667 106.794667 0 0 0-106.666667-106.666667H192a106.794667 106.794667 0 0 0-106.666667 106.666667v384a106.794667 106.794667 0 0 0 106.666667 106.666667h384a106.794667 106.794667 0 0 0 106.666667-106.666667v-50.816l225.130666 112.576A21.333333 21.333333 0 0 0 938.666667 746.666667V277.333333a21.333333 21.333333 0 0 0-10.133334-18.133333z", "p-id": "9785" }))); } }));
}
function BlackCamera() {
    return (React.createElement(Icon, { style: { fontSize: '48px' }, component: function () { return (React.createElement("svg", { width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 1024 1024" },
            React.createElement("path", { d: "M928.533333 259.2a21.226667 21.226667 0 0 0-20.736-0.96L682.666667 370.837333V320a106.794667 106.794667 0 0 0-106.666667-106.666667H192a106.794667 106.794667 0 0 0-106.666667 106.666667v384a106.794667 106.794667 0 0 0 106.666667 106.666667h384a106.794667 106.794667 0 0 0 106.666667-106.666667v-50.816l225.130666 112.576A21.333333 21.333333 0 0 0 938.666667 746.666667V277.333333a21.333333 21.333333 0 0 0-10.133334-18.133333z", "p-id": "9785" }))); } }));
}
function ComingSoon() {
    return (React.createElement("div", { className: "coming-soon-img" }));
}
