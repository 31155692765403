var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import PieSvg from "./pie.svg";
import React from "react";
import { Icon } from "antd-min";
export var Pie = function (props) { return (React.createElement(Icon, __assign({ component: PieSvg, className: 'svg-icon' }, props))); };
