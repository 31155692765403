var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { injectable } from '../../util/di';
import VerificationServiceBase from '../app/servicebase/verificationservicebase';
var TSILogService = /** @class */ (function (_super) {
    __extends(TSILogService, _super);
    function TSILogService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceRoute = { prefix: 'rep/v1/tsi/logs', suffix: '{id}' };
        return _this;
    }
    TSILogService.prototype.getStudentPlayCount = function (params) {
        return this.get('students/{studentId}/today/count', params);
    };
    TSILogService.prototype.getMediaProgress = function (params) {
        return this.get('students/{studentId}/lessons/{lessonId}/medias/{mediaId}/progress', null, params);
    };
    TSILogService.prototype.clearMediaProgress = function (params) {
        return this.delete('students/{studentId}/lessons/{lessonId}/medias/{mediaId}/progress', null, params);
    };
    TSILogService.prototype.uploadLessonLog = function (params) {
        return this.create('lessons/events', params);
    };
    TSILogService.prototype.uploadMediaLog = function (params) {
        return this.create('medias', params);
    };
    TSILogService.prototype.uploadMediaProgress = function (params) {
        return this.create('progress', params);
    };
    TSILogService.prototype.getPlayLogs = function (params) {
        return this.get('students/{studentId}/units/{unit}', null, params);
    };
    TSILogService.prototype.getUnitBadges = function (params) {
        return this.get('students/{studentId}/badges', null, params);
    };
    TSILogService.prototype.getUnitLessonBadges = function (params) {
        return this.get('students/{studentId}/units/{unit}/badges', null, params);
    };
    TSILogService = __decorate([
        injectable()
    ], TSILogService);
    return TSILogService;
}(VerificationServiceBase));
export { TSILogService };
