var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import { Button } from "antd-min";
import { PathConfig } from "@app/config/pathconfig";
import { GLUtil, MessageHelper, NotificationType } from "gl-commonui";
1;
import playlist_home from "@app/style/images/playlist_home.png";
import playlist_home_dark from "@app/style/images/playlist_home_dark.png";
import Badge, { BadgeType } from "./badge";
import Blink from "@app/components/blink";
import { TeachingLocale } from "@app/locales/localeid";
import { fmtMsg } from "@app/util";
var images = {
    back_image: 'back-image',
};
var icons = {
    back: 'back',
    song_icon: 'song-icon',
    movie_icon: 'movie-icon',
    story_icon: 'story-icon',
};
var iconClassNames = {
    "Song": "song-icon",
    "Movie": "movie-icon",
    "Story": "story-icon",
    "VPC": "vpc-icon"
};
// export default function UnitButton({ history, unit, userId, from, isShowBadge, percentage = 0 }) {
var UnitButton = /** @class */ (function (_super) {
    __extends(UnitButton, _super);
    function UnitButton(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = {
            activeBlink: false,
        };
        return _this;
    }
    UnitButton.prototype.componentWillReceiveProps = function (nextProps) {
        if (nextProps.activeBlink != this.props.activeBlink) {
            this.setState({ activeBlink: nextProps.activeBlink });
        }
    };
    UnitButton.prototype.render = function () {
        var _a = this.props, history = _a.history, unit = _a.unit, userId = _a.userId, from = _a.from, isShowBadge = _a.isShowBadge, percentage = _a.percentage;
        return this.renderBlink(history, unit, userId, from, isShowBadge, percentage);
    };
    UnitButton.prototype.renderBlink = function (history, unit, userId, from, isShowBadge, percentage) {
        var _this = this;
        if (this.props.enableBlink) {
            return (React.createElement(Blink, { className: "blinkable-button", activeBlink: this.state.activeBlink, activedBlinkCallback: function () { _this.props.activedBlinkCallback && _this.props.activedBlinkCallback(); } },
                React.createElement(Button, { className: "button", type: "primary", onClick: function () { return navigateBy(history, userId, from, TeachingLocale.MsgWarningUnit); } },
                    unit > 0 ? "Unit " + unit : "LittleSEED " + Math.abs(unit),
                    isShowBadge ? React.createElement(Badge, { percentage: percentage, type: BadgeType.Small }) : React.createElement("img", { src: unit != 7 ? playlist_home : playlist_home_dark }))));
        }
        else {
            return (React.createElement(Button, { className: "button", type: "primary", onClick: function () { return navigateBy(history, userId, from, TeachingLocale.MsgWarningUnit); } },
                unit > 0 ? "Unit " + unit : "LittleSEED " + Math.abs(unit),
                isShowBadge ? React.createElement(Badge, { percentage: percentage, type: BadgeType.Small }) : React.createElement("img", { src: unit != 7 ? playlist_home : playlist_home_dark })));
        }
    };
    return UnitButton;
}(Component));
export default UnitButton;
function navigateBy(history, userId, from, message) {
    if (userId) {
        history.push({ pathname: GLUtil.pathStringify(PathConfig.Units, { userId: userId }), search: GLUtil.queryStringify({ from: from }) });
    }
    else {
        MessageHelper.Message(NotificationType.Warning, fmtMsg(message));
    }
}
