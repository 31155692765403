var PathConfig = /** @class */ (function () {
    function PathConfig() {
    }
    PathConfig.Home = "/";
    PathConfig.Children = "/children";
    PathConfig.CurrentUser = "/users/:userId";
    PathConfig.Session = "/users/:userId/session";
    PathConfig.Unit = "/users/:userId/:versionId/units/:unit";
    PathConfig.LessonsFlow = "/users/:userId/:versionId/units/:unit/lessonsflow";
    PathConfig.Lessons = "/users/:userId/:versionId/units/:unit/lessons";
    PathConfig.LessonPlayer = "/users/:userId/:versionId/units/:unit/lessons/:lessonId/medias/:mediaId";
    PathConfig.Playlist = "/users/:userId/:versionId/units/:unit/playlists";
    PathConfig.PlaylistPlayer = "/users/:userId/:versionId/units/:unit/playlists/:sequence";
    PathConfig.PlayContents = "/playcontents";
    PathConfig.Library = "/users/:userId/:versionId/units/:unit/library";
    PathConfig.LibraryPlayer = "/users/:userId/:versionId/units/:unit/library/:sequence";
    PathConfig.Units = "/users/:userId/units";
    PathConfig.Listen = "/users/:userId/:versionId/units/:unit/listen";
    PathConfig.DVDPlayer = "/users/:userId/:versionId/units/:unit/listen/:sequence";
    PathConfig.Videoplayer = "/videoplayer";
    PathConfig.Welcome = "/users/:userId/:versionId/units/:unit/:path/welcome/player";
    PathConfig.CodeLogin = "/code";
    return PathConfig;
}());
export { PathConfig };
