var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var AccessStatus;
(function (AccessStatus) {
    AccessStatus[AccessStatus["DEFAULT"] = 0] = "DEFAULT";
    AccessStatus[AccessStatus["LOCK"] = 1] = "LOCK";
    AccessStatus[AccessStatus["UNLOCK"] = 2] = "UNLOCK";
})(AccessStatus || (AccessStatus = {}));
export default {
    namespace: "studentcodebase",
    state: {
        accessStatus: AccessStatus.DEFAULT
    },
    reducers: {
        setAccessStatus: function (state, action) {
            return __assign({}, state, { accessStatus: action.payload });
        }
    }
};
export function setAccessStatus(status) {
    return { type: "studentcodebase/setAccessStatus", payload: status };
}
