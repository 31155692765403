var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GLGlobal } from "gl-commonui";
export default {
    namespace: 'playlistplayer',
    state: {
        paused: false,
    },
    reducers: {
        paused: function (state, action) {
            return __assign({}, state, { paused: action.payload });
        },
    },
};
export function playlistplayerPaused(state) {
    return { type: 'playlistplayer/paused', payload: state };
}
export function pausePlaylistPlayer(paused) {
    GLGlobal.store.dispatch(playlistplayerPaused(paused));
}
export function isPlaylistPlayerPaused() {
    return GLGlobal.store.getState().playlistplayer.paused;
}
